import getCookie from './get'

function deleteCookie (name, domain = null) {
  const cookie = getCookie(name, domain)
  if (cookie) {
    let cookieString = `${name}=${cookie}`
    if (domain) cookieString += `;domain=${domain}`
    // cookieString += `;max-age=0`
    const now = new Date()
    now.setMonth(now.getMonth() - 1)
    cookieString += `;expires=${now.toUTCString()}`
    cookieString += ';path=/'
    document.cookie = cookieString
  }
}

export default deleteCookie
