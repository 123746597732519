// import { backbone } from '../lib/http.js'
// import io from 'socket.io-client'

// ------------------------------------- Login Actions
export const requestLogin = (creds) => {
  return {
    type: 'LOGIN_REQUEST',
    isFetching: true,
    isAuthenticated: false,
    creds
  }
}

export const receiveLogin = (res) => {
  return {
    type: 'LOGIN_SUCCESS',
    isFetching: false,
    isAuthenticated: true,
    id_token: res.token,
    full_name: `${res.data.users[0].first_name} ${res.data.users[0].last_name}`,
    isAdmin: res.data.users[0].admin,
    user: res.data.users[0]
  }
}

export const loginError = (message) => {
  return {
    type: 'LOGIN_FAILURE',
    isFetching: false,
    isAuthenticated: false,
    message
  }
}

// --------------------------------------------------------
// Calls the API to get a token and
// dispatches actions along the way
// --------------------------------------------------------
// export const loginUser = creds => {
//   return async dispatch => {
//     dispatch(requestLogin(creds))
//     try {
//       const result = await backbone.post('/auth?zoom=companies,departments,policies', { email: creds.email, password: creds.password })
//       let res = result.data
//       window.localStorage.setItem('authtoken', res.token)

//       dispatch(receiveLogin(res))

//       if (JSON.parse(process.env.WEBSOCKETS_ENABLED)) {
//         let socket = io.connect(process.env.NOTIFICATION_API)
//         let userId = res.data.users[0].id
//         // setup a room on the server for receiving notifications
//         socket.emit('USER_ID', userId)
//       }
//       // ensures that everything loads correctly by reloading the webpage
//       // window.location.reload()
//     } catch (err) {
//       const error = Object.assign({}, err).response.data.error
//       dispatch(loginError(error))
//     }
//   }
// }

export const LOGOUT = 'LOGOUT'

export const requestLogout = () => {
  return {
    type: 'LOGOUT'
  }
}

export const addUserToState = (user) => {
  return {
    type: 'ADD_USER_TO_STATE',
    isFetching: false,
    isAuthenticated: true,
    full_name: `${user.first_name} ${user.last_name}`,
    isAdmin: user.admin,
    user
  }
}

export const removeUserFromState = () => {
  return {
    type: 'CLEAR_USER_FROM_STATE'
  }
}
