import uuidv4 from '../uuidv4'

function WSEvent (obj) {
  const { payload, eventName, contextId, contextName } = obj
  const event = {}
  event.eventId = uuidv4()
  event.eventName = eventName
  event.timestamp = new Date()
  event.contextId = contextId
  event.contextName = contextName
  event.payload = payload
  return event
}

export default WSEvent
