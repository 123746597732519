const normalTheme = {
  tabs: {
    backgroundColor: 'rgb(229, 229, 229)'
  },
  palette: {
    primary1Color: '#242F3E',
    primary2Color: '#242F3E',
    accent1Color: 'rgba(176, 182, 190, 0.87)',
    accent2Color: 'rgba(176, 182, 190, 0.87)'
  }
}

export default normalTheme
