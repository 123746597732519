/* eslint-disable no-case-declarations */
const userInterface = (
  state = {
    title: 'BACKBONE',
    errorCaller: null,
    isFetching: null,
    menuSidebarOpen: false,
    notificationSidebarOpen: false,
    snackbarOpen: false,
    snackbarMessage: '',
    fetchingNotifications: false,
    notifications: [],
    unreadNotifications: 0,
    notificationCount: 0,
    errorMessage: ''
  }, action) => {
  switch (action.type) {
  case 'BACKBONE_ERROR':
    const error = Object.assign({}, action.errorMessage)
    return Object.assign({}, state, {
      errorMessage: error.response.data.error,
      errorCaller: action.errorCall,
      isFetching: false
    })
  case 'OPEN_MENU_SIDEBAR':
    return Object.assign({}, state, {
      menuSidebarOpen: action.menuSidebarOpen
    })
  case 'CLOSE_MENU_SIDEBAR':
    return Object.assign({}, state, {
      menuSidebarOpen: action.menuSidebarOpen
    })
  case 'OPEN_NOTIFICATION_SIDEBAR':
    return Object.assign({}, state, {
      notificationSidebarOpen: action.notificationSidebarOpen
    })
  case 'CLOSE_NOTIFICATION_SIDEBAR':
    return Object.assign({}, state, {
      notificationSidebarOpen: action.notificationSidebarOpen
    })
  case 'REQUESTED_NOTIFICATIONS':
    return Object.assign({}, state, {
      fetchingNotifications: action.fetchingNotifications
    })
  case 'REQUESTED_NOTIFICATIONS_SUCCESS':
    return Object.assign({}, state, {
      fetchingNotifications: action.fetchingNotifications,
      notifications: action.notifications,
      notificationCount: action.notificationCount,
      unreadNotifications: action.unreadNotifications
    })
  case 'REQUESTED_NOTIFICATIONS_FAILED':
    return Object.assign({}, state, {
      fetchingNotifications: action.fetchingNotifications,
      errorMessage: action.message
    })
  case 'MARK_NOTIFICATION_AS_READ':
    return Object.assign({}, state, {
      notifications: action.notifications
    })
  case 'DELETE_NOTIFICATION':
    return Object.assign({}, state, {
      notifications: action.notifications
    })
  case 'OPEN_SNACKBAR':
    return Object.assign({}, state, {
      snackbarOpen: action.snackbarOpen,
      snackbarMessage: action.snackbarMessage
    })
  case 'CLOSE_SNACKBAR':
    return Object.assign({}, state, {
      snackbarOpen: action.snackbarOpen,
      snackbarMessage: action.snackbarMessage
    })
  case 'UPDATE_APP_TITLE':
    return Object.assign({}, state, {
      title: action.title
    })
  case 'ADD_NOTIFICATION':
    return Object.assign({}, state, {
      unreadNotifications: action.unreadNotifications,
      notificationCount: action.notificationCount,
      notifications: action.notifications
    })
  case 'CLEAR_NOTIFICATIONS':
    return Object.assign({}, state, {
      unreadNotifications: 0,
      notificationCount: 0,
      notifications: []
    })
  case 'UNREAD_NOTIFICATION_COUNT':
    return Object.assign({}, state, {
      unreadNotifications: action.unreadNotifications
    })
  default:
    return state
  }
}

export default userInterface
