import React from 'react'
import { connect } from 'react-redux'
import Drawer from 'material-ui/Drawer'
import IconButton from 'material-ui/IconButton'
import { List, ListItem } from 'material-ui/List'
import Avatar from 'material-ui/Avatar'
import IconMenu from 'material-ui/IconMenu'
import MenuItem from 'material-ui/MenuItem'
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert'
import Divider from 'material-ui/Divider'
import moment from 'moment'
import appIcons from '../../lib/appIcons'
import CircularProgress from 'material-ui/CircularProgress'
import {
  closeNotificationSidebar,
  unreadCount
} from '../../actions/UserInterfaceActions.jsx'
import Snackbar from 'material-ui/Snackbar'
import NotificationIcon from 'material-ui/svg-icons/social/notifications-none'
import ActiveNotification from 'material-ui/svg-icons/social/notifications'
// methods
import componentDidMount from './methods/componentDidMount'
import componentWillUnmount from './methods/componentWillUnmount'
import _onScroll from './methods/_onScroll'
import readNotification from './methods/readNotification'
import markUnread from './methods/markUnread'
import hideNotification from './methods/hideNotification'
import readAll from './methods/readAll'
import getNotifications from './methods/getNotifications'
import socketConnection from './methods/socketConnection'
import handleClick from './methods/handleClick'
/* eslint-disable camelcase */
import UNSAFE_componentWillUpdate from './methods/UNSAFE_componentWillUpdate'

const mapStateToProps = state => {
  return {
    sidebarOpen: state.userInterface.notificationSidebarOpen,
    unreadNotifications: state.userInterface.unreadNotifications
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeNotificationSidebar: () => {
      dispatch(closeNotificationSidebar())
    },
    unreadCount: (count) => {
      dispatch(unreadCount(count))
    }
  }
}

class _NotificationSidebar extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false,
      sidebarOpen: false,
      notifications: [],
      unreadCount: 0,
      preventLoad: false,
      page: 0,
      isLoadingMore: false,
      errorSnackbar: false,
      errorMsg: null,
      bottom: false,
      isAuthenticated: false,
      user: null,
      notificationSnackbar: false
    }
    this.componentDidMount = componentDidMount.bind(this)
    this.componentWillUnmount = componentWillUnmount.bind(this)
    this._onScroll = _onScroll.bind(this)
    this.readNotification = readNotification.bind(this)
    this.markUnread = markUnread.bind(this)
    this.hideNotification = hideNotification.bind(this)
    this.readAll = readAll.bind(this)
    this.getNotifications = getNotifications.bind(this)
    this.socketConnection = socketConnection.bind(this)
    this.UNSAFE_componentWillUpdate = UNSAFE_componentWillUpdate.bind(this)
    this.handleClick = handleClick.bind(this)
  }

  render () {
    const {
      unreadCount,
      sidebarOpen,
      isLoading,
      isLoadingMore,
      notifications,
      preventLoad,
      notificationSnackbar,
      errorSnackbar,
      errorMsg
    } = this.state

    const {
      closeNotificationSidebar,
      user
    } = this.props

    if (!user) return null

    return (
      <div
        style={{ float: 'right' }}
        onScroll={(e) => this._onScroll(e)}
      >
        {unreadCount > 0 &&
          <div
            style={{
              position: 'absolute',
              right: 17,
              top: 6,
              zIndex: 2,
              color: '#FFF',
              background: '#ed4956',
              padding: `0px ${unreadCount >= 10 ? 3 : 6}px`,
              borderRadius: 30,
              fontSize: 8,
              boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
              cursor: 'pointer'
            }}
            onClick={(e) => this.handleClick(e)}
          >
            {unreadCount}
          </div>
        }
        <IconButton
          style={{
            height: unreadCount > 0 ? 30 : 35,
            width: unreadCount > 0 ? 30 : 35,
            fontWeight: 'bold',
            fontSize: 16,
            padding: 0,
            borderRadius: 30,
            marginTop: 3,
            zIndex: 1,
            color: '#FFF',
            position: 'absolute',
            right: unreadCount === 0 ? 14 : 16,
            top: unreadCount === 0 ? 2 : 5
          }}
          onClick={(e) => this.handleClick(e)}
        >
          {unreadCount > 0 &&
            <ActiveNotification
              color={'#FFF'}
              style={{
                width: 10,
                height: 10
              }}
            />
          }
          {unreadCount === 0 &&
            <NotificationIcon
              color={'#fff'}
              style={{
                width: 10,
                height: 10
              }}
            />
          }
        </IconButton>
        <Drawer
          width={430}
          openSecondary
          open={sidebarOpen}
          docked={false}
          onRequestChange={() => {
            this.setState({
              sidebarOpen: !sidebarOpen,
              page: 1,
              notifications: [],
              preventLoad: false,
              isLoading: false,
              isLoadingMore: false
            })
          }}
          style={{ textAlign: 'left' }}
        >
          <List
            style={{ paddingTop: 0, width: '100%' }}
            id='notificationSidebar'
          >
            <div style={{
              paddingTop: 5,
              width: '100%',
              lineHeight: 2,
              borderBottom: '1px solid rgb(224, 224, 224)'
            }}>
              <div
                style={{
                  width: 'calc(50% - 15px)',
                  paddingLeft: 15,
                  fontSize: '80%',
                  float: 'left',
                  fontWeight: 'bold',
                  color: 'rgba(0, 0, 0, 0.54)'
                }}
              >
                Notifications {unreadCount > 0 && `(${unreadCount})`}
              </div>
              <div
                style={{
                  width: 'calc(50% - 25px)',
                  paddingRight: 20,
                  fontSize: '80%',
                  float: 'left',
                  textAlign: 'right',
                  color: 'rgba(0, 0, 0, 0.54)',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  this.readAll()
                }}
              >
                Mark All as Read
              </div>
              <div style={{ clear: 'both' }} />
            </div>
            <Divider />
            {isLoading &&
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  paddingTop: 10
                }}
              >
                <CircularProgress size={30} thickness={3} color={'rgb(237, 242, 250)'} />
              </div>
            }
            {!isLoading && notifications.length === 0 &&
              <div
                style={{ width: '100%', textAlign: 'center', paddingTop: 10, color: 'rgba(0, 0, 0, 0.30)', fontStyle: 'italic', fontSize: '80%' }}
              >
                No Notifications
              </div>
            }
            {notifications.map((notification, i) => {
              return ([
                <ListItem
                  key={i}
                  onClick={async () => {
                    await this.readNotification(i)
                    closeNotificationSidebar()
                    window.location.href = notification.url || '/'
                  }}
                  leftAvatar={
                    <Avatar
                      style={{ marginTop: 8 }}
                      backgroundColor={notification.read ? ('#fff') : ('#edf2fa')}
                      src={appIcons(notification.source)}
                    />
                  }
                  style={{
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    backgroundColor: notification.read ? ('#fff') : ('#edf2fa'),
                    borderBottom: '1px solid #dddfe2'
                  }}
                  rightIconButton={
                    <IconMenu
                      onClick={(e) => e.preventDefault()}
                      iconButtonElement={
                        <IconButton
                          touch
                        >
                          <MoreVertIcon color={'rgb(188, 188, 188)'} />
                        </IconButton>
                      }
                    >
                      {notification.read &&
                        <MenuItem
                          style={{ fontSize: '80%', lineHeight: 3, minHeight: 10 }}
                          onClick={() => {
                            this.markUnread(i)
                          }}
                        >
                          Mark as Unread
                        </MenuItem>
                      }
                      {!notification.read &&
                        <MenuItem
                          style={{ fontSize: '80%', lineHeight: 3, minHeight: 10 }}
                          onClick={async () => {
                            await this.readNotification(i)
                          }}
                        >
                          Mark as Read
                        </MenuItem>
                      }

                      <MenuItem
                        style={{ fontSize: '80%', lineHeight: 3, minHeight: 10 }}
                        onClick={async () => {
                          await this.hideNotification(i)
                        }}
                      >
                        Hide Notification
                      </MenuItem>
                    </IconMenu>
                  }
                  primaryText={(notification.title || notification.source)}
                  secondaryText={
                    <div style={{ padding: 0, margin: 0 }}>
                      <p style={{ padding: 0, margin: 0, fontSize: '80%' }}>{(notification.body || notification.message)}</p>
                      <p style={{ padding: 0, margin: 0, fontSize: '80%' }}>{moment(notification.created_at).fromNow()}</p>
                    </div>
                  }
                  secondaryTextLines={2}
                />,
                <Divider />
              ])
            })}
            {isLoadingMore && !preventLoad &&
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  paddingTop: 10
                }}
              >
                <CircularProgress size={30} thickness={3} color={'rgba(0, 0, 0, 0.54)'} />
              </div>
            }
            {preventLoad &&
              <div
                style={{ width: '100%', textAlign: 'center', paddingTop: 10, color: 'rgba(0, 0, 0, 0.30)', fontStyle: 'italic', fontSize: '80%' }}
              >
                No more notifications to load
              </div>
            }
          </List>
        </Drawer>
        <Snackbar
          open={notificationSnackbar}
          message={'New Unread Notification Received'}
          bodyStyle={{ backgroundColor: 'rgb(222, 119, 31)' }}
          autoHideDuration={4000}
          onRequestClose={() => {
            this.setState({ notificationSnackbar: false })
          }}
        />
        <Snackbar
          open={errorSnackbar}
          message={errorMsg || ''}
          bodyStyle={{ backgroundColor: '#ff1744' }}
          autoHideDuration={5000}
          onRequestClose={() => {
            this.setState({ errorSnackbar: false, errorMsg: null })
          }}
        />
      </div>
    )
  }
}

const NotificationSidebar = connect(
  mapStateToProps,
  mapDispatchToProps
)(_NotificationSidebar)

export default NotificationSidebar
