// The auth reducer. The starting state sets authentication
// based on a token being in local storage. In a real app,
// we would also want a util to check if the token is expired.
const Auth = (
  state = {
    isFetching: false,
    isAuthenticated: window.localStorage.getItem('id_token'),
    isAdmin: false,
    user: null,
    full_name: null,
    authtoken: null,
    errorMessage: ''
  },
  action
) => {
  switch (action.type) {
  case 'RETRIEVED_BASE_USER':
    return Object.assign({}, state, {
      user: action.user
    })
  case 'LOGIN_REQUEST':
    return Object.assign({}, state, {
      isFetching: true,
      isAuthenticated: false,
      user: action.creds
    })
  case 'LOGIN_SUCCESS':
    return Object.assign({}, state, {
      isFetching: false,
      isAuthenticated: true,
      user: action.user,
      full_name: action.full_name,
      authtoken: action.id_token,
      // tokenExpire: action.tokenExpire,
      isAdmin: action.isAdmin,
      errorMessage: ''
    })
  case 'LOGIN_FAILURE':
    return Object.assign({}, state, {
      isFetching: false,
      isAuthenticated: false,
      errorMessage: action.message
    })
  case 'LOGOUT_SUCCESS':
    return Object.assign({}, state, {
      isFetching: true,
      isAuthenticated: false
    })
  case 'LOGOUT':
    return Object.assign({}, state, {
      isFetching: false,
      isAuthenticated: false,
      user: null,
      full_name: null,
      authtoken: null,
      tokenExpire: null,
      isAdmin: null,
      errorMessage: null
    })
  case 'ADD_USER_TO_STATE':
    return Object.assign({}, state, {
      isFetching: action.isFetching,
      isAuthenticated: action.isAuthenticated,
      full_name: action.full_name,
      isAdmin: action.isAdmin,
      user: action.user
    })
  case 'CLEAR_USER_FROM_STATE':
    return Object.assign({}, state, {
      isFetching: false,
      isAuthenticated: false,
      isAdmin: false,
      user: null,
      full_name: null,
      authtoken: null,
      errorMessage: ''
    })
  case 'CLEAR_INITIAL_LOGIN':
    return Object.assign({}, state, {
      entryUrl: '/'
    })
  default:
    return state
  }
}

export default Auth
