import cookies from '../../lib/cookies'
import { AUTHTOKEN, DOMAIN } from '../../lib/constants'
import { NINETYDAYSASSECONDS } from './config'
import http from '../../lib/http'
import { cloneDeep } from 'lodash'
import logger from '../../lib/log'

const log = logger('containers:App:setHTTPToken')

function setHTTPToken (token) {
  log.debug('iterating over axios http methods and setting Authorization token')
  Object.entries(http).map(name => {
    const KEY = name[0]
    // ! after the ES vulnerability with Java was discovered, we moved ES behind our virtual cloud
    // ! it now uses authentication behind oauth and no longer needs AWS headers
    // const skippers = ['ES', 'elasticSearch']
    const skippers = []
    if (!skippers.includes(KEY)) {
      const headers = cloneDeep(http[KEY].defaults.headers)
      headers.common.Authorization = token
      http[KEY].defaults.headers = headers
    }
  })
  cookies.set(AUTHTOKEN, token, NINETYDAYSASSECONDS, process.env.NODE_ENV === 'production' ? DOMAIN : null)
}

export default setHTTPToken
