import { BB } from '../../../lib/http'
import logger from '../../../lib/log'

const log = logger('containers:sidebars:NotificationSidebar:methods:markUnread')

function markUnread (index) {
  const notifications = this.state.notifications
  notifications[index].read = true
  this.setState({
    notifications
  }, async () => {
    try {
      // -----------------------------------------------------------------------------------
      // send patch request to server to mark as read
      // -----------------------------------------------------------------------------------
      const res = await BB.patch(`/notifications/${notifications[index].id}`, { read: false })
      notifications[index].read = res.data.data.notifications[0].read
      this.setState({
        notifications,
        unreadCount: res.data.unread_count
      }, () => {
        this.props.unreadCount(res.data.unread_count)
      })
    } catch (err) {
      log.fatal('this.markUnread()')
      log.fatal(`${err}`)
      this.setState({
        errorSnackbar: true,
        errorMsg: 'Error Updating Notification'
      }, () => {
        throw err
      })
    }
  })
}

export default markUnread
