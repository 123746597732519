/* eslint-disable react/prop-types */
import React from 'react'
import qs from 'qs'
import { changeAppTitle } from '../../actions/UserInterfaceActions.jsx'
import FlatButton from 'material-ui/FlatButton'
import IconMenu from 'material-ui/IconMenu'
import MenuItem from 'material-ui/MenuItem'
import { map } from 'lodash'
import DownIcon from 'material-ui/svg-icons/navigation/arrow-drop-down'
import { connect } from 'react-redux'
import Divider from 'material-ui/Divider'
import LogoutIcon from 'material-ui/svg-icons/action/power-settings-new'
import SettingsIcon from 'material-ui/svg-icons/action/settings'
import DevicesIcon from 'material-ui/svg-icons/hardware/devices-other'
import { Link } from 'react-router-dom'
import WorkIcon from 'material-ui/svg-icons/communication/business'
import Subheader from 'material-ui/Subheader'
import PersonIcon from 'material-ui/svg-icons/action/settings-applications'
import HelpIcon from 'material-ui/svg-icons/action/help-outline'
import FeedbackIcon from 'material-ui/svg-icons/action/feedback'
import Logo from '../../lib/assets/img/cac_logo.svg'
import NotificationSidebar from '../../containers/sidebars/NotificationSidebar.jsx'
import versionNumber from '../../lib/versionNumber'
import './Header.css'

// const file = '[components.Header.Header]'

const mapStateToProps = state => {
  return {
    state,
    title: state.userInterface.title,
    isAuthenticated: state.userAuth.isAuthenticated,
    user: state.userAuth.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeAppTitle: (title) => {
      dispatch(changeAppTitle(title))
    }
  }
}

class Header extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      dropDownOpen: false,
      menuOpen: false,
      userOptions: false
    }
    this.handleRequestClose = this.handleRequestClose.bind(this)
    this.handleTouchTap = this.handleTouchTap.bind(this)
    this.handleDisplay = this.handleDisplay.bind(this)
  }

  render () {
    const { selectedCompany, companies, isAuthenticated, user, darkTheme } = this.props
    return (
      <nav
        id='navbar'
        className={`${darkTheme ? 'darkBG' : 'lightBG'}`}
      >
        <div
          style={{
            clear: 'both',
            lineHeight: 2,
            height: 40,
            paddingRight: 25
          }}
        >
          <img
            className='logo_image'
            src={Logo}
            style={{ float: 'left', cursor: user ? 'pointer' : null }}
            onClick={e => {
              e.preventDefault()
              user && this.props.openMenuSidebar()
            }}
          />
          <div
            style={{
              float: 'left',
              lineHeight: 1.7,
              fontSize: '1.5em',
              cursor: 'pointer',
              fontWeight: 'bold',
              color: '#fff',
              fontFamily: 'Titillium Web, sans-serif',
              marginLeft: 5
            }}
            onClick={e => {
              e.preventDefault()
              this.props.openMenuSidebar()
            }}
          >
            {'BACKBONE'}<span style={{ paddingLeft: 2, fontSize: 12, marginTop: 60, fontStyle: 'italic' }}>{versionNumber}</span>
          </div>
          {user &&
            <div style={{ float: 'right', marginRight: 10 }}>
              <NotificationSidebar
                isAuthenticated={isAuthenticated}
                user={user}
              />
              {user &&
                <div style={{ float: 'right', marginRight: 5, paddingTop: 2 }}>
                  <IconMenu
                    desktop
                    menuStyle={{ padding: 0 }}
                    iconButtonElement={
                      <FlatButton
                        id={'loggedInUserDropdown'}
                        onClick={() => this.setState({ userOptions: true })}
                        label={user.full_name}
                        style={{ lineHeight: 0 }}
                        labelStyle={{
                          textTransform: 'none',
                          color: darkTheme ? 'rgb(10, 112, 156)' : '#FFF'
                        }}
                        labelPosition={'after'}
                        icon={<PersonIcon color={darkTheme ? 'rgb(10, 112, 156)' : '#FFF'} />}
                      />
                    }
                    open={this.state.userOptions}
                    onRequestChange={(bool, string) => {
                      this.setState({ userOptions: !this.state.userOptions })
                    }}
                  >
                    <MenuItem
                      primaryText={'Account Settings'}
                      leftIcon={<SettingsIcon />}
                      containerElement={<Link to={'/account'} />}
                    />
                    <MenuItem
                      primaryText={'Device Management'}
                      leftIcon={<DevicesIcon />}
                      containerElement={<Link to={'/account/devices'} />}
                    />
                    <Divider style={{ margin: 0 }} />
                    <MenuItem
                      primaryText={'Need Help?'}
                      leftIcon={<HelpIcon />}
                      containerElement={<a target='_blank' href={'https://controlair.atlassian.net/servicedesk/customer/portals'} rel="noreferrer" />}
                    />
                    <MenuItem
                      primaryText={'Send Feedback'}
                      leftIcon={<FeedbackIcon />}
                      containerElement={<a target='_blank' href={'https://controlair.atlassian.net/servicedesk/customer/portal/1'} rel="noreferrer" />}
                    />
                    <Divider style={{ margin: 0 }} />
                    <MenuItem
                      primaryText={'Logout'}
                      leftIcon={<LogoutIcon />}
                      containerElement={<Link to={'/logout'} />}
                    />
                    {/* <Divider style={{ margin: 0 }} />
                    ! FOR TESTING ONLY
                    <MenuItem
                      primaryText={'Datadog Test'}
                      leftIcon={<FeedbackIcon />}
                      onClick={() => {
                        throw new Error('Datadog Test Error')
                      }}
                    /> */}
                  </IconMenu>
                </div>
              }
              {selectedCompany && companies.length > 0 &&
                <div style={{ float: 'right', marginRight: 5, paddingTop: 2 }}>
                  <IconMenu
                    desktop
                    iconButtonElement={
                      <FlatButton
                        onClick={() => this.setState({ menuOpen: true })}
                        label={selectedCompany && selectedCompany.name}
                        style={{ lineHeight: 0 }}
                        labelStyle={{
                          textTransform: 'none',
                          color: darkTheme ? 'rgb(10, 112, 156)' : '#fff'
                        }}
                        labelPosition={'before'}
                        icon={<DownIcon color={darkTheme ? 'rgb(10, 112, 156)' : '#fff'} />}
                      />
                    }
                    open={this.state.menuOpen}
                    onRequestChange={(bool, string) => {
                      this.setState({ menuOpen: !this.state.menuOpen })
                    }}
                    onChange={(e, value) => {
                      map(companies, (company, i) => {
                        if (company.ts_id === value) {
                          this.props.selectCompany(company)
                        }
                      })
                    }}
                  >
                    <Subheader>Change Company</Subheader>
                    {map(companies, (c, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={c.ts_id}
                          primaryText={c.name}
                          leftIcon={<WorkIcon />}
                        />
                      )
                    })}
                  </IconMenu>
                </div>
              }
            </div>
          }
        </div>
      </nav>
    )
  }

  handleTouchTap (e) {
    // This prevents ghost click.
    e.preventDefault()
    this.setState({
      dropDownOpen: true,
      anchorEl: e.currentTarget
    })
  }

  handleRequestClose () {
    this.setState({
      dropDownOpen: false
    })
  }

  handleDisplay (boolean) {
    if (boolean === false || boolean === null || boolean === undefined) {
      return { display: 'none' }
    } else {
      return { lineHeight: 3.4, width: '50%', backgroundColor: 'red' }
    }
  }

  componentDidMount () {
    // attempt to set a company if none is set
    if (!this.props.selectedCompany && this.props.companies.length > 0) {
      let set = false
      map(this.props.companies, (c, i) => {
        // if the user has CAE and no default, set it to CAE
        if (c.ts_id === 12) {
          set = true
          this.props.selectCompany(this.props.companies[i])
        }
      })
      if (!set) this.props.selectCompany(this.props.companies[0])
    }

    const resource = qs.parse(window.location).pathname.split('/')[1]
    switch (resource) {
    case 'arsenal':
      // this.props.changeAppTitle('BACKBONE')
      break
    default:
      this.props.changeAppTitle('BACKBONE')
      break
    }
  }

  handleOnClick (e) {
    e.preventDefault()
    this.props.requestLogout()
  }
}
const header = connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
export default header
