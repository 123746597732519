import logger from '../../../lib/log'
// import cookies from '../../../lib/cookies'
// import websocket from '../../../lib/websocket'
// import { AUTHTOKEN } from '../../../lib/constants'
const log = logger('containers:sidebars:NotificationSidebar:methods:socketConnection')
const MESSAGE_WEBSOCKET = 'MESSAGE_WEBSOCKET'

function socketConnection (id) {
  return new Promise((resolve, reject) => {
    try {
      if (JSON.parse(process.env.WEBSOCKETS_ENABLED)) {
        window.addEventListener(MESSAGE_WEBSOCKET, event => {
          console.log({ event })
          const { data } = event.detail
          const n = data.data.notifications[0]
          const { notifications, unreadCount } = this.state

          notifications.unshift(n)

          this.setState({
            notifications,
            unreadCount: unreadCount + 1,
            notificationSnackbar: true
          })
        })
      }
      resolve()
    } catch (err) {
      log.fatal('this.socketConnection()')
      log.fatal(`${err}`)
      reject(err)
    }
  })
}

export default socketConnection
