import React from 'react'
import { datadogRum } from '@datadog/browser-rum'

const asyncComponent = (getComponent) => {
  // return AsyncComponent class component
  class AsyncComponent extends React.Component {
    constructor (props) {
      super(props)
      this.state = {
        Component: AsyncComponent.Component // first time similar to static Component = null
      }
    }

    async componentDidMount () {
      if (!this.state.Component) {
        try {
          /**
           * if this.state.Component is true value then getComponent promise resolve with .then() method
           * For simplicity, I haven't caught an error, but you can catch any errors or show loading bar or animation to user etc.
           */
          const { default: Component } = await getComponent()
          AsyncComponent.Component = Component
          this.setState({ Component })
        } catch (err) {
          // log to datadog
          datadogRum.addError(err.error, { comment: 'Error while loading a page resource, attempting a page refresh'} )
          window.location.reload()
        }
      }
    }

    render () {
      const { Component } = this.state // destructing Component from this.state
      if (Component) {
        return <Component {...this.props} />
      } else {
        return null
      }
    }
  }
  return AsyncComponent
}

export default asyncComponent
