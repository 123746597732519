import moment from 'moment'
import tokenClaim from '../tokenClaim'
import msFromDate from '../msFromDate'
import { MSTHRESHOLD } from '../config'
import EventBuilder from '../EventBuilder'
import websocket from '../../../lib/websocket'
import WSEvent from '../../../lib/websocket/WSEvent'
import logger from '../../../lib/log'

const log = logger('containers:App:broadcastChannel:index')
const CONNECTED = 'CONNECTED'
const DISCONNECTED = 'DISCONNECTED'
const TOKENUPDATED = 'TOKENUPDATED'
const OAUTH_TOKEN_REFRESHED = 'OAUTH_TOKEN_REFRESHED'
const SET_REFRESH_LISTENER = 'SET_REFRESH_LISTENER'
const SOCKET_EVENT = 'SOCKET_EVENT'
const tabManagement = 'tabManagement'
const MESSAGE_WEBSOCKET = 'MESSAGE_WEBSOCKET'
const KNOWNPAGES = 'KNOWNPAGES'

function extractMasterPage (knownPages) {
  // sort by host
  knownPages = knownPages.sort((a, b) => a.host.toLowerCase().localeCompare(b.host.toLowerCase()))

  // extract the first host after sorting
  const host = String(knownPages[0].host)

  // filter out pages that do not belong to host
  const hostPages = knownPages.filter(page => page.host === host)

  // extract a list of clientId's
  const clientIds = hostPages.map(page => page.clientId)

  // sort and return the first clientId
  const clientId = clientIds.sort()[0]

  return { clientId, host }
}

function broadcastChannel (clientId) {
  return new Promise((resolve, reject) => {
    try {
      const { token, user } = this.state

      const knownPages = [{ host: process.env.BACKBONE, clientId }]
      let masterPage = { host: process.env.BACKBONE, clientId }

      let socket = websocket(token)

      socket.on('message', data => {
        window.dispatchEvent(EventBuilder(MESSAGE_WEBSOCKET, { data }))
      })

      socket.on(SOCKET_EVENT, event => {
        const { eventName, contextName, payload } = event
        if (contextName === tabManagement && payload.clientId !== clientId) {
          if (eventName === CONNECTED) {
            const hasPage = knownPages.filter(page => page.clientId !== clientId).length > 0
            if (!hasPage) knownPages.push(payload)

            masterPage = extractMasterPage(knownPages)

            socket.emit(SOCKET_EVENT,
              WSEvent({
                eventName: KNOWNPAGES,
                contextId: token,
                contextName: tabManagement,
                payload: {
                  knownPages,
                  host: process.env.BACKBONE,
                  clientId
                }
              })
            )
          }

          if (eventName === DISCONNECTED) {
            // remove page with matching clientId
            const index = knownPages.findIndex(page => page.clientId === payload.clientId)
            if (index > -1) knownPages.splice(index, 1)
            // elect a new page as master
            masterPage = extractMasterPage(knownPages)
          }

          if (eventName === KNOWNPAGES) {
            // iterate over each page and add if one does not exist
            payload.knownPages.forEach(page => {
              const hasPage = knownPages.filter(page => page.clientId !== clientId).length > 0
              if (!hasPage) knownPages.push(page)
            })
            masterPage = extractMasterPage(knownPages)
          }

          if (eventName === TOKENUPDATED) {
            const { oauthToken } = payload
            const claims = tokenClaim(oauthToken)
            const expires = moment.unix(claims.exp).toDate()
            const msRemaining = msFromDate(expires)
            const timeToRefresh = msRemaining - MSTHRESHOLD
            this.setState({ token: oauthToken }, () => {
              this.setHTTPToken(oauthToken)
              socket.disconnect()
              socket = websocket(oauthToken)
              socket.connect()
              window.dispatchEvent(EventBuilder(SET_REFRESH_LISTENER, { timeToRefresh }))
            }, () => {
              log.debug(`${TOKENUPDATED} - ${JSON.stringify(event.data)}`)
            })
          }
        }

        if (payload.clientId !== clientId) {
          this.setState({ masterPage }, () => {
            const isMaster = masterPage.clientId === clientId && masterPage.host === process.env.BACKBONE
            log.info(`${moment().toISOString()} - ${eventName} - ${isMaster ? 'elected to master **' : 'set to slave'}`)
          })
        }
      })

      const tokenRefreshedEvent = event => {
        const { oauthToken } = event.detail
        log.debug(`${OAUTH_TOKEN_REFRESHED} - ${JSON.stringify(event.detail)}`)
        // bc.postMessage({ eventName: TOKENUPDATED, payload: { oauthToken } })
        socket.emit(SOCKET_EVENT,
          WSEvent({
            eventName: TOKENUPDATED,
            contextId: oauthToken,
            contextName: tabManagement,
            payload: {
              oauthToken,
              clientId,
              host: process.env.BACKBONE
            }
          })
        )
      }

      // add event listener
      window.addEventListener(OAUTH_TOKEN_REFRESHED, tokenRefreshedEvent)

      // close out of tab
      window.onbeforeunload = () => {
        socket.emit(SOCKET_EVENT,
          WSEvent({
            eventName: DISCONNECTED,
            contextId: token,
            contextName: tabManagement,
            payload: {
              clientId,
              host: process.env.BACKBONE
            }
          })
        )
        window.removeEventListener(OAUTH_TOKEN_REFRESHED, tokenRefreshedEvent)
        window.removeEventListener(SET_REFRESH_LISTENER, null)
        // ! we must manually disconnect from socket server
        // ! we turned off automatic disconnect in the
        // ! websocket library file
        socket.disconnect()
      }

      // announce that a connection has opened
      socket.on('connect', () => {
        socket.emit('USER_ID', user.id)
        socket.emit(SOCKET_EVENT,
          WSEvent({
            eventName: CONNECTED,
            contextId: token,
            contextName: tabManagement,
            payload: {
              clientId,
              host: process.env.BACKBONE
            }
          })
        )
      })

      this.setState({ masterPage }, () => resolve())
    } catch (err) {
      reject(err)
    }
  })
}

export default broadcastChannel
