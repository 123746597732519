import backboneAuthImg from '../lib/assets/img/backbone-auth.svg'
import crewManagementImg from '../lib/assets/img/crew-management.svg'
import snapshotImg from '../lib/assets/img/estimating-scorecard.svg'
import deliveriesImg from '../lib/assets/img/deliveries.svg'
import titanImg from '../lib/assets/img/titan2.0.svg'
import titanThreeImg from '../lib/assets/img/titan-3.svg'
import fieldTimeImg from '../lib/assets/img/field-time.svg'
import userSettingsImg from '../lib/assets/img/user-settings.svg'
import bidBoardImg from '../lib/assets/img/bid-board.svg'
import receivingLogImg from '../lib/assets/img/receiving-log.svg'
import jiraImg from '../lib/assets/img/jira-logo.svg'
import techSupportImg from '../lib/assets/img/support-tickets.svg'
import kibanaImg from '../lib/assets/img/security.svg'
import layoutImg from '../lib/assets/img/logout.svg'
import systemImg from '../lib/assets/img/system.svg'
import adminSettingsImg from '../lib/assets/img/admin-settings.svg'
import jobDataImg from '../lib/assets/img/job-data.svg'
import employeeListsImg from '../lib/assets/img/employee-lists.svg'
import financialsImg from '../lib/assets/img/financials.svg'
import myDataImg from '../lib/assets/img/my-data.svg'
import fieldPipelineImg from '../lib/assets/img/field-pipeline.svg'
import forecastImg from '../lib/assets/img/forecast.svg'
import businessIntelligenceImg from '../lib/assets/img/business-intelligence.svg'
import devicesImg from '../lib/assets/img/devices.svg'
import powerBiImg from '../lib/assets/img/power-bi.svg'
import returnLogImg from '../lib/assets/img/return-log.svg'
import pricingTableImg from '../lib/assets/img/pricing-table.svg'
import fieldTimeSettingsImg from '../lib/assets/img/fieldtime-settings.svg'
import golfImg from '../lib/assets/img/golf.svg'
import healthImg from '../lib/assets/img/thermometer.svg'
import cacLogoImg from '../lib/assets/img/cac_logo.svg'
import cacLogoDarkImg from '../lib/assets/img/cacLogoDark.svg'
import requisitionImg from '../lib/assets/img/requisition-center.svg'

function appIcons (apiName) {
  switch (apiName) {
  case 'backbone-auth':
    return backboneAuthImg
  case 'crew-management':
    return crewManagementImg
  case 'snapshot':
    return snapshotImg
  case 'deliveries':
    return deliveriesImg
  case 'titan':
    return titanImg
  case 'titan-3':
    return titanThreeImg
  case 'field-time':
    return fieldTimeImg
  case 'user-settings':
    return userSettingsImg
  case 'bid-board':
    return bidBoardImg
  case 'receiving-log':
    return receivingLogImg
  case 'jira':
    return jiraImg
  case 'tech-support':
    return techSupportImg
  case 'kibana':
    return kibanaImg
  case 'logout':
    return layoutImg
  case 'system':
    return systemImg
  case 'admin-settings':
    return adminSettingsImg
  case 'job-data':
    return jobDataImg
  case 'employee-lists':
    return employeeListsImg
  case 'financials':
    return financialsImg
  case 'my-data':
    return myDataImg
  case 'field-pipeline':
    return fieldPipelineImg
  case 'forecast':
    return forecastImg
  case 'business-intelligence':
    return businessIntelligenceImg
  case 'devices':
    return devicesImg
  case 'power-bi':
    return powerBiImg
  case 'return-log':
    return returnLogImg
  case 'pricing-table':
    return pricingTableImg
  case 'fieldtime-settings':
    return fieldTimeSettingsImg
  case 'golf':
    return golfImg
  case 'health':
    return healthImg
  case 'requisition-center':
    return requisitionImg
  case 'control-center':
    return cacLogoDarkImg
  default:
    return cacLogoImg
  }
}

export default appIcons
