import moment from 'moment'
import tokenClaim from './tokenClaim'
import msFromDate from './msFromDate'
import { MSTHRESHOLD } from './config'
import getUser from './getUser'
import uuidv4 from '../../lib/uuidv4'
import EventBuilder from './EventBuilder'
import logger from '../../lib/log'
import createHistory from 'history/createBrowserHistory'
import { datadogRum } from '@datadog/browser-rum'

const customHistory = createHistory()

const log = logger('containers:App:componentDidMount')

const OAUTH_TOKEN_REFRESHED = 'OAUTH_TOKEN_REFRESHED'
const SET_REFRESH_LISTENER = 'SET_REFRESH_LISTENER'

async function componentDidMount () {
  const clientId = uuidv4()

  let backboneUserRequested = false
  try {
    // console.log(
    //   '*****\n',
    //   'loading a cookie\n',
    //   JSON.stringify(document.cookie),
    //   '\n******'
    // )
    log.debug('⚡️ loaded app, determining if authtoken exists or is valid')
    let oauthToken = await this.handleAuthToken()
    const publicUrls = [
      '/authorize',
      '/knowledge-center',
      '/oauth/promise-success',
      '/account-invalid',
      '/unauthorized',
      '/error-page'
    ]
    let includes = false
    publicUrls.map(base => {
      if (window.location.href.includes(base)) includes = true
    })
    if (!oauthToken && !includes) {
      this.setState({
        siteEntry: window.location.href
      })
      customHistory.push('/authorize')
    }

    if (oauthToken && !includes) {
      // parse the JWT token
      let claims = tokenClaim(oauthToken)

      // extract the token expiration timestamp then calculate when its time
      // to refresh our oauthToken
      let expires = moment.unix(claims.exp).toDate()
      let msRemaining = msFromDate(expires)
      let timeToRefresh = msRemaining - MSTHRESHOLD

      // query backbone to get the user account
      backboneUserRequested = true
      const user = await getUser()
      backboneUserRequested = false

      const selectedCompany = this.selectedCompany(user)
      const titanToken = this.setTitanToken(user)
      const fieldTimeToken = this.setFieldTimeToken(user)

      // ensure that all HTTP objects have our token
      this.setHTTPToken(oauthToken)

      this.setState({
        initialLoader: false,
        companies: user._embedded.companies,
        policies: user._embedded.policies,
        departments: user._embedded.departments,
        token: oauthToken,
        selectedCompany,
        user,
        titanToken,
        fieldTimeToken
      }, async () => {
        // if there is a redirect in memory, take the user there
        const possibleRedirect = window.localStorage.getItem('siteEntry')
        if (possibleRedirect) {
          window.localStorage.removeItem('siteEntry')
          window.location.href = possibleRedirect
        }
        // add user to redux
        this.props.addUserToState(user)

        // function to be called on refresh (should run infinitely if browser
        // stays open)
        const triggerRefresh = async () => {
          const { masterPage } = this.state
          if (masterPage && masterPage.clientId === clientId) {
            try {
              log.debug('attempt refresh triggered')
              oauthToken = await this.attemptTokenRefresh(oauthToken)
              claims = tokenClaim(oauthToken)
              expires = moment.unix(claims.exp).toDate()
              msRemaining = msFromDate(expires)
              timeToRefresh = msRemaining - MSTHRESHOLD
              this.setState({ token: oauthToken }, () => {
                this.setHTTPToken(oauthToken)
                window.dispatchEvent(EventBuilder(OAUTH_TOKEN_REFRESHED, { oauthToken }))
                // update all http Authorization headers
                // ensure that all HTTP objects have our token
                setTimeout(triggerRefresh, timeToRefresh)
              })
            } catch (err) {
              this.stripHTTPToken()
              window.location.href = '/authorize'
            }
          }
        }

        //
        setTimeout(triggerRefresh, timeToRefresh)

        await this.broadcastChannel(clientId)

        const setRefreshListener = event => {
          timeToRefresh = event.detail.timeToRefresh
          setTimeout(triggerRefresh, timeToRefresh)
        }

        window.addEventListener(SET_REFRESH_LISTENER, setRefreshListener)

        /**
         * This is a hack to get around the issue of a chunk failing to load
         * When a chunk fails to load, usually because new code has been
         * deployed, the users page should be refreshed to get the new code.
         * -
         * we will temporarily log the error to newrelic before enabling refresh
         */
        window.addEventListener('error', (e) => {
          if (e.error.name === 'ChunkLoadError') {
            // log to datadog
            datadogRum.addError(e.error, { comment: 'ChunkLoadError detected from application root' })

            // refresh the page
            // window.location.reload()
          }
        })

        // window.onbeforeunload = () => {
        //   window.removeEventListener(SET_REFRESH_LISTENER, setRefreshListener)
        // }
      })
    } else {
      this.stripHTTPToken()
      this.setState({
        initialLoader: false,
        loginExpiredModalOpen: false,
        titanToken: null,
        fieldTimeToken: null,
        token: null,
        user: null,
        sidebarOpen: false
      })
    }
  } catch (err) {
    if (backboneUserRequested) {
      console.log({ err })
      window.location.href = '/account-invalid'
    }
    this.stripHTTPToken()
    this.setState({
      initialLoader: false,
      loginExpiredModalOpen: true,
      errorMsg: 'Unsuccessful Authentication Attempt',
      errorSnackbar: true
    })
    throw err
  }
}

export default componentDidMount
