/* eslint-disable no-async-promise-executor */
import { BB } from '../../../lib/http'
import logger from '../../../lib/log'

const log = logger('containers:sidebars:NotificationSidebar:methods:hideNotification')

function hideNotification (index) {
  return new Promise(async (resolve, reject) => {
    try {
      const notifications = this.state.notifications
      // -----------------------------------------------------------------------------------
      // send patch request to server to mark as read
      // -----------------------------------------------------------------------------------
      try {
        const res = await BB.delete(`/notifications/${notifications[index].id}`)
        notifications.splice(index, 1)
        this.setState({
          notifications,
          unreadCount: res.data.unread_count
        }, () => {
          this.props.unreadCount(res.data.unread_count)
        })
      } catch (err) {
        log.fatal('this.hideNotification()')
        log.fatal(`${err}`)
        this.setState({ errorSnackbar: true, errorMsg: 'Error Hiding Notification' }, () => {
          throw err
        })
      }
    } catch (err) {
      reject(err)
    }
  })
}

export default hideNotification
