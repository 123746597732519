import { BB } from '../../../lib/http'
import logger from '../../../lib/log'

const log = logger('containers:sidebars:NotificationSidebar:methods:readAll')

function readAll () {
  const notifications = this.state.notifications
  notifications.map((n, i) => { notifications[i].read = true })
  this.setState({
    notifications
  }, async () => {
    try {
      const res = await BB.patch(`/notifications?per=${10}&order_by_many=created_at:desc`, { read: true })
      this.setState({
        notifications: res.data.data.notifications,
        unreadCount: res.data.unread_count,
        page: res.data.pagination.page,
        preventLoad: false,
        isLoading: false,
        isLoadingMore: false,
        bottom: false
      }, () => {
        this.props.unreadCount(res.data.unread_count)
      })
    } catch (err) {
      log.fatal('this.readAll()')
      log.fatal(`${err}`)
      this.setState({ errorSnackbar: true, errorMsg: 'Error Marking all Notifications as Read' })
      throw err
    }
  })
}

export default readAll
