function setUser (user) {
  let isLoggedIn = false
  if (user && user.active) isLoggedIn = true
  const updateState = { user, isLoggedIn }
  if (user && user.token) {
    const { token } = user
    updateState.token = token
  }
  this.setState(updateState)
}

export default setUser
