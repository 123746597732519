function handleClick (e) {
  e.preventDefault()
  this.setState({
    sidebarOpen: !this.state.sidebarOpen,
    page: 1,
    notifications: [],
    preventLoad: false,
    isLoading: true,
    isLoadingMore: false
  }, async () => {
    await this.getNotifications(1)
  })
}

export default handleClick
