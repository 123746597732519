/* eslint-disable react/prop-types */
/* eslint-disable camelcase */

import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect, Switch } from 'react-router-dom'
import asyncComponent from './components/asyncComponent.jsx'
import logger from './lib/log'
const log = logger('Router')

const randomString = () => Math.random().toString(36).slice(2)
const SSO = asyncComponent(() => import('./containers/SSO.jsx'))
const Dashboard = asyncComponent(() => import('./containers/Dashboard/Dashboard.jsx'))
const CostCodes = asyncComponent(() => import('./containers/jobData/CostCodes.jsx'))
const Transactions = asyncComponent(() => import('./containers/jobData/Transactions.jsx'))
const ClientRedirect = asyncComponent(() => import('./containers/Redirect.jsx'))
const Logout = asyncComponent(() => import('./components/pages/Logout.jsx'))
const ErrorPage = asyncComponent(() => import('./components/pages/ErrorPage.jsx'))
const Login = asyncComponent(() => import('./containers/Login.jsx'))
const AdminPanel = asyncComponent(() => import('./containers/AdminPanel/List.jsx'))
const AdminPanelUser = asyncComponent(() => import('./containers/AdminPanelUser/AdminPanelUser.jsx'))
const UserSettings = asyncComponent(() => import('./containers/UserSettings.jsx'))
const AppSettings = asyncComponent(() => import('./containers/AppSettings.jsx'))
const CrewManagement = asyncComponent(() => import('./containers/crewManagement/CrewManagement.jsx'))
const CrewMember = asyncComponent(() => import('./containers/crewManagement/CrewMember.jsx'))
const EstimatingScorecard2 = asyncComponent(() => import('./containers/EstimatingScorecard2.jsx'))
const Deliveries = asyncComponent(() => import('./containers/Deliveries.jsx'))
const EditDelivery = asyncComponent(() => import('./containers/deliveries/EditDelivery.jsx'))
const JobData = asyncComponent(() => import('./containers/jobData/Main.jsx'))
const JobDataView = asyncComponent(() => import('./containers/jobData/View.jsx'))
const EmployeeLists = asyncComponent(() => import('./containers/EmployeeLists.jsx'))
const Financials = asyncComponent(() => import('./containers//financials/Financials.jsx'))
const TaxDisplay = asyncComponent(() => import('./containers/governmentTax/TaxDisplay.jsx'))
const MyData = asyncComponent(() => import('./containers/my_data/MyData.jsx'))
const DeptSelect = asyncComponent(() => import('./containers/my_data/DeptSelect.jsx'))
const TitanBaseSales = asyncComponent(() => import('./containers/my_data/reports/TitanBaseSales.jsx'))
const SyncStatus = asyncComponent(() => import('./containers/SyncStatus.jsx'))
const FieldtimeDashboard = asyncComponent(() => import('./containers/jobData/FieldtimeDashboard.jsx'))
const AuthenticationRouter = asyncComponent(() => import('./containers/field-pipeline/AuthenticationRouter.jsx'))
const ForemanHome = asyncComponent(() => import('./containers/field-pipeline/ForemanHome.jsx'))
const OfficeHome = asyncComponent(() => import('./containers/field-pipeline/OfficeHome.jsx'))
const DeptScorecard = asyncComponent(() => import('./containers/my_data/scorecards/DeptScorecard.jsx'))
const FWOForm = asyncComponent(() => import('./containers/forms/FWOForm.jsx'))
const Resources = asyncComponent(() => import('./containers/resource-tracking/Resources.jsx'))
const FieldWorkOrder = asyncComponent(() => import('./containers/fieldWorkOrders/Single/Index.jsx'))
const FieldWorkOrders = asyncComponent(() => import('./containers/fieldWorkOrders/JobFWOs/Index.jsx'))
const FieldWorkOrderPrintable = asyncComponent(() => import('./containers/fieldWorkOrders/Printable/Printable.jsx'))
const AllFieldWorkOrders = asyncComponent(() => import('./containers/fieldWorkOrders/All/Index.jsx'))
const JSRPortal = asyncComponent(() => import('./containers/field-pipeline/office/JSRPortal.jsx'))
const JSRReport = asyncComponent(() => import('./containers/field-pipeline/JSRReport/Index.jsx'))
const JSRReports = asyncComponent(() => import('./containers/field-pipeline/JSRReports.jsx'))
const FieldOpsDashboard = asyncComponent(() => import('./containers/fieldOpsDashboard/FieldOpsDashboard.jsx'))
const JobUpdates = asyncComponent(() => import('./containers/field-pipeline/JobUpdates.jsx'))
const JobUpdate = asyncComponent(() => import('./containers/field-pipeline/JobUpdateV2.jsx'))
const ForgotPassword = asyncComponent(() => import('./containers/ForgotPassword.jsx'))
const TitanLogin = asyncComponent(() => import('./containers/account/ConnectedAccounts/Titan/TitanLogin.jsx'))
const FieldTimeLogin = asyncComponent(() => import('./containers/account/ConnectedAccounts/FieldTime/FieldTimeLogin.jsx'))
const ReceivingLogMain = asyncComponent(() => import('./containers/receivingLog/Main.jsx'))
const ReceivingLogNew = asyncComponent(() => import('./containers/receivingLog/New.jsx'))
const ReceivingLogEdit = asyncComponent(() => import('./containers/receivingLog/Edit.jsx'))
const SuperReportsMain = asyncComponent(() => import('./containers/superReports/Main.jsx'))
const PowerBILanding = asyncComponent(() => import('./containers/bi/Landing.jsx'))
const PowerBIReports = asyncComponent(() => import('./containers/bi/Reports.jsx'))
const PowerBIReport = asyncComponent(() => import('./containers/bi/Report.jsx'))
const Devices = asyncComponent(() => import('./containers/admin/Devices.jsx'))
const EqualityFundJobs = asyncComponent(() => import('./containers/equalityFund/components/EqualityFundJobs/EqualityFundJobs.jsx'))
const SingleJobEF = asyncComponent(() => import('./containers/equalityFund/components/SingleJobEF/SingleJobEF.jsx'))
const KnowledgeCenter = asyncComponent(() => import('./containers/knowledgeCenter/Main.jsx'))
const ArticleWebWontLoad = asyncComponent(() => import('./containers/knowledgeCenter/articles/WebWontLoad.jsx'))
const ArticleSupportedBrowsers = asyncComponent(() => import('./containers/knowledgeCenter/articles/SupportedBrowsers.jsx'))
const Feedback = asyncComponent(() => import('./containers/feedback/Main.jsx'))
const CloudChangeOrders = asyncComponent(() => import('./containers/cloudChangeOrders/Main.jsx'))
const SelectedJobCloudCO = asyncComponent(() => import('./containers/cloudChangeOrders/SelectedJobView.jsx'))
const ChangeOrderView = asyncComponent(() => import('./containers/cloudChangeOrders/ChangeOrderView.jsx'))
const SageChangeOrders = asyncComponent(() => import('./containers/sageChangeOrders/Main.jsx'))
const ReturnLog = asyncComponent(() => import('./containers/returnLog/Main.jsx'))
const NewReturnItem = asyncComponent(() => import('./containers/returnLog/New.jsx'))
const EditReturnItem = asyncComponent(() => import('./containers/returnLog/Edit.jsx'))
const EstimateLogs = asyncComponent(() => import('./containers/estimateManagement/EstimatingLog.jsx'))
const BudgetBuilder = asyncComponent(() => import('./containers/estimateManagement/BudgetBuilder.jsx'))
const ChangeOrderFormatter = asyncComponent(() => import('./containers/estimateManagement/changeOrderProposalComponents/ChangeOrderFormatter.jsx'))
const EstimateDirectory = asyncComponent(() => import('./containers/estimatingTemplateManager/EstimateDirectory.jsx'))
const PTDirectory = asyncComponent(() => import('./containers/estimatingTemplateManager/pmPricing/PricingTableDirectory.jsx'))
const PricingTemplates = asyncComponent(() => import('./containers/estimatingTemplateManager/pmPricing/v2/PricingTemplates.jsx'))
const PMVersionsList = asyncComponent(() => import('./containers/estimatingTemplateManager/pmPricing/legacy/PMVersionsList.jsx'))
const SinglePMView = asyncComponent(() => import('./containers/estimatingTemplateManager/pmPricing/legacy/SinglePMView.jsx'))
const LaborTemplates = asyncComponent(() => import('./containers/estimatingTemplateManager/laborPricing/LaborTemplates.jsx'))
const TandemJobList = asyncComponent(() => import('./containers/tandem/Main.jsx'))
const UnlockRequest = asyncComponent(() => import('./containers/redirects/UnlockRequest.jsx'))
const TitanList = asyncComponent(() => import('./containers/salesGraph/pages/TitanList.jsx'))
const SalesGraph = asyncComponent(() => import('./containers/salesGraph/Main.jsx'))
const COList = asyncComponent(() => import('./containers/salesGraph/pages/COList.jsx'))
const Scorecard = asyncComponent(() => import('./containers/scorecard/Main.jsx'))
const FieldTime = asyncComponent(() => import('./containers/fieldTime/Main.jsx'))
const FieldTimeAccounts = asyncComponent(() => import('./containers/fieldTime/Accounts.jsx'))
const FieldTimeUser = asyncComponent(() => import('./containers/fieldTime/User.jsx'))
const FieldTimeTimecardIndex = asyncComponent(() => import('./containers/fieldTime/TimecardIndex/TimecardIndex.jsx'))
const PullStockList = asyncComponent(() => import('./containers/pullStock/PullStockList.jsx'))
const TCReview = asyncComponent(() => import('./containers/fieldTime/TCReview.jsx'))
const MassTextMessenger = asyncComponent(() => import('./containers/massTextMessenger/MassTextMessenger.jsx'))
const ServiceBidManager = asyncComponent(() => import('./containers/serviceBidManager/Index.jsx'))
const CustomerMenu = asyncComponent(() => import('./containers/customerManager/Customer.jsx'))
const CustomerAdminCenter = asyncComponent(() => import('./containers/customerManager/CustomerAdminCenter.jsx'))
const GlobalCompany = asyncComponent(() => import('./containers/customerManager/GlobalCompany.jsx'))
const GlobalContact = asyncComponent(() => import('./containers/customerManager/GlobalContact.jsx'))
const MergeCompanies = asyncComponent(() => import('./containers/customerManager/MergeCompanies.jsx'))
const OrganizationManager = asyncComponent(() => import('./containers/customerManager/OrganizationManager.jsx'))
const PromiseSuccess = asyncComponent(() => import('./containers/PromiseSuccess.jsx'))
const ForemanMigrationTool = asyncComponent(() => import('./containers/foremanMigrationTool/ForemanMigrationTool.jsx'))
const AccountInvalid = asyncComponent(() => import('./containers/AccountInvalid.jsx'))
const RequisitionCenterList = asyncComponent(() => import('./containers/requisitionCenter/RequisitionCenterList.jsx'))
const NewRequisition = asyncComponent(() => import('./containers/requisitionCenter/NewRequisition.jsx'))
const EditRequisition = asyncComponent(() => import('./containers/requisitionCenter/EditRequisition.jsx'))
const VendorList = asyncComponent(() => import('./containers/vendorList/VendorList.jsx'))
const ClearCookies = asyncComponent(() => import('./containers/ClearCookies.jsx'))
const WebhookList = asyncComponent(() => import('./containers/webHooks/List.jsx'))
const TitanApprovalReviewIndex = asyncComponent(() => import('./containers/titanApprovalReview/Index.jsx'))
const ManHourForecastsAll = asyncComponent(() => import('./containers/manpowerForecasts/All/All.jsx'))
const ManHourForecastsView = asyncComponent(() => import('./containers/manpowerForecasts/View/View.jsx'))
const ManHourBurnReport = asyncComponent(() => import('./containers/manpowerForecasts/BurnReport/BurnReport.jsx'))
const ManHourEventLog = asyncComponent(() => import('./containers/manpowerForecasts/EventLog/EventLog.jsx'))
const ManloadInsights = asyncComponent(() => import('./containers/manloadInsights/ManloadInsights.jsx'))
const HealthCheck = asyncComponent(() => import('./containers/HealthCheck/HealthCheck.jsx'))
const AllDocumentation = asyncComponent(() => import('./containers/Documentation/All/All.jsx'))
const ViewDocumentation = asyncComponent(() => import('./containers/Documentation/View/View.jsx'))
const FieldTimeEmployeeReports = asyncComponent(() => import('./containers/fieldTime/Reports/EmployeeReport.jsx'))
const TitanModuleList = asyncComponent(() => import('./containers/titan/List/Index.jsx'))
const WorkPoolsList = asyncComponent(() => import('./containers/workPools/List/Index.jsx'))
const WorkPoolsView = asyncComponent(() => import('./containers/workPools/View/Index.jsx'))
const JobSafetySheets = asyncComponent(() => import('./containers/JobSafetySheets/Index.jsx'))
const AttributeManager = asyncComponent(() => import('./containers/attributeManager/Index.jsx'))
const FieldTimeChargeHistory = asyncComponent(() => import('./containers/fieldTimeChargeHistory/Index.jsx'))
const RiskManagementEmployeeIndex = asyncComponent(() => import('./containers/riskManagement/employeeManager/Index.jsx'))
const HRTickets = asyncComponent(() => import('./containers/riskManagement/hrTickets/Index.jsx'))
const RiskManagementForms = asyncComponent(() => import('./containers/riskManagement/forms/Index.jsx'))
const JobsiteSafetyReport = asyncComponent(() => import('./containers/riskManagement/jobsiteSafetyReport/Index.jsx'))
const JobsiteSafetyReportSuccessPage = asyncComponent(() => import('./containers/riskManagement/successPage/SuccessPage.jsx'))
const JobsiteInspectionRequestIndex = asyncComponent(() => import('./containers/riskManagement/jobsiteInspectionRequestIndex/Index.jsx'))
const ToolboxTalkTemplateManager = asyncComponent(() => import('./containers/riskManagement/toolboxTalkTemplateManager/Index.jsx'))
const ToolboxTalkTemplateView = asyncComponent(() => import('./containers/riskManagement/toolboxTalkTemplateView/Index.jsx'))
const ToolboxTalkRequests = asyncComponent(() => import('./containers/riskManagement/toolboxTalkRequests/Index.jsx'))
const ToolboxTalkForm = asyncComponent(() => import('./containers/riskManagement/toolboxTalkForm/Index.jsx'))
const Audits = asyncComponent(() => import('./containers/audits/Index.jsx'))
const JobCostSummaryReport = asyncComponent(() => import('./containers/jobData/jobCostSummaryReport/Index.jsx'))
const DetailingForecast = asyncComponent(() => import('./containers/jobData/detailingForecast/Index.jsx'))
const DetailingForecastLog = asyncComponent(() => import('./containers/jobData/detailingForecastLog/Index.jsx'))
const Watchtower = asyncComponent(() => import('./containers/watchtower/Index.jsx'))
const JobCostSummaryIndex = asyncComponent(() => import('./containers/jobCostSummaryIndex/Index.jsx'))
const NewContractStatusReport = asyncComponent(() => import('./containers/contractStatusReports/newContractStatusReport/NewContractStatusReport.jsx'))
const EditContractStatusReport = asyncComponent(() => import('./containers/contractStatusReports/editContractStatusReport/EditContractStatusReport.jsx'))
const ListContractStatusReports = asyncComponent(() => import('./containers/contractStatusReports/listContractStatusReports/ContractStatusReports.jsx'))
const HealthReportIndex = asyncComponent(() => import('./containers/healthReports/reportIndex/Index.jsx'))
const CostCodeFailedEvents = asyncComponent(() => import('./containers/healthReports/costCodeFailedEvents/CostCodeFailedEvents.jsx'))
const EventInspector = asyncComponent(() => import('./containers/healthReports/inspectEvents/Main.jsx'))
const CachedJobNumbersReport = asyncComponent(() => import('./containers/healthReports/cachedJobNumbers/CachedJobNumbersReport.jsx'))
const HiltiList = asyncComponent(() => import('./containers/hilti/hiltiList/ViewList.jsx'))
const HiltiViewSingle = asyncComponent(() => import('./containers/hilti/hiltiViewSingle/ViewSingle.jsx'))
// --------------------------------------------------------
// The router is the main switchboard, controlling access
// to all pages within backbone.
// If the user object has not been loaded into memory,
// the router should force the user to go to the login
// page.
// --------------------------------------------------------
class Router extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const {
      isLoggedIn,
      user,
      setUser,
      company,
      selectCompany,
      selectedCompany,
      token,
      titanToken,
      fieldTimeToken,
      darkTheme,
      darkThemeEnabled,
      siteEntry
    } = this.props

    return (
      <div>
        <Switch>
          <Route exact path='/unauthorized' render={() => <ErrorPage documentTitle={'Error 401 (Unauthorized)'} heading={'401 Unauthorized'} title={'User Not Allowed'} description={'Only authorized users can sign into this application'} redirectionLink={'/authorize'} linkText={'Back to Sign In'} />} />
          <Route exact path='/error-page' render={() => <ErrorPage documentTitle={'Error 500 (Internal Server Error)'} heading={'500 Internal Server Error'} title={'Something went wrong'} description={''} redirectionLink={'/authorize'} linkText={'Back to Sign In'} />} />
          <Route exact path='/authorize' render={props => <SSO {...props} siteEntry={siteEntry} />} />
          <Route exact path='/oauth/promise-success' component={PromiseSuccess} />
          <Route exact path='/account-invalid' component={AccountInvalid} />
          <Route exact path='/admin/devices' render={() => (!this.userIsAdmin() ? (<Redirect exact to='/' />) : (<Route exact path='/admin/devices' render={props => { return (<Devices {...props} user={user} darkTheme={darkTheme} darkThemeEnabled={darkThemeEnabled} />) }} />))} />
          <Route exact path='/admin-panel/:id' render={() => (!this.userIsAdmin() ? (<Redirect exact to='/' />) : (<Route exact path='/admin-panel/:id' render={props => <AdminPanelUser user={user} token={token} {...props} />} />))} />
          <Route exact path='/admin-panel' render={() => (!this.userIsAdmin() ? (<Redirect exact to='/' />) : (<Route exact path='/admin-panel' component={AdminPanel} />))} />
          <Route exact path='/audits' render={() => (!this.userIsAdmin() ? (<Redirect exact to='/' />) : (<Route exact path='/audits' render={props => { return (<Audits {...props} user={user} company={company} darkTheme={darkTheme} darkThemeEnabled={darkThemeEnabled} />) }} />))} />
          <Route exact path='/redirect' render={props => { return (<ClientRedirect {...props} user={user} setUser={setUser} company={company} selectCompany={selectCompany} timestamp={new Date().toString()} />) }} />
          <Route exact path='/account/apps/:application' render={() => (!isLoggedIn ? (<Redirect exact to='/' />) : (<Route exact path='/account/apps/:application' component={AppSettings} />))} />
          <Route exact path='/account/access-groups' render={() => (!isLoggedIn ? (<Redirect exact to='/' />) : (<Route exact path='/account/access-groups' render={props => { return (<UserSettings {...props} token={token} user={user} setUser={setUser} darkTheme={darkTheme} darkThemeEnabled={darkThemeEnabled} />) }} />))} />
          <Route exact path='/account/access-policies' render={() => (!isLoggedIn ? (<Redirect exact to='/' />) : (<Route exact path='/account/access-policies' render={props => { return (<UserSettings {...props} token={token} user={user} setUser={setUser} darkTheme={darkTheme} darkThemeEnabled={darkThemeEnabled} />) }} />))} />
          <Route exact path='/account/devices' render={() => (!isLoggedIn ? (<Redirect exact to='/' />) : (<Route exact path='/account/devices' render={props => { return (<UserSettings {...props} token={token} user={user} setUser={setUser} darkTheme={darkTheme} darkThemeEnabled={darkThemeEnabled} />) }} />))} />
          <Route exact path='/account' render={() => (!isLoggedIn ? (<Redirect exact to='/' />) : (<Route exact path='/account' render={props => { return (<UserSettings {...props} token={token} user={user} setUser={setUser} darkTheme={darkTheme} darkThemeEnabled={darkThemeEnabled} />) }} />))} />
          <Route exact path='/attribute-manager' render={() => (!isLoggedIn ? (<Redirect exact to='/' />) : (<Route exact path='/attribute-manager' render={props => { return (<AttributeManager {...props} token={token} user={user} company={company} />) }} />))} />
          <Route exact path='/bi/departments/:department/:report' render={() => (!this.hasPolicy('Business Intelligence') ? (<Redirect exact to='' />) : (<Route exact path='/bi/departments/:department/:report' render={props => { return (<PowerBIReport user={user} company={company} selectCompany={selectCompany} key={randomString()} {...props} />) }} />))} />
          <Route exact path='/bi/departments/:department' render={() => (!this.hasPolicy('Business Intelligence') ? (<Redirect exact to='/' />) : (<Route exact path='/bi/departments/:department' render={props => { return (<PowerBIReports user={user} company={company} selectCompany={selectCompany} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/bi/departments' render={() => (!this.hasPolicy('Business Intelligence') ? (<Redirect exact to='/' />) : (<Route exact path='/bi/departments' render={props => { return (<PowerBILanding user={user} company={company} selectCompany={selectCompany} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/connect/titan2' render={() => (!isLoggedIn ? (<Redirect exact to='/' />) : (<Route exact path='/connect/titan2' render={props => { return (<TitanLogin {...props} token={token} user={user} setUser={setUser} />) }} />))} />
          <Route exact path='/connect/fieldtime' render={() => (!isLoggedIn ? (<Redirect exact to='/' />) : (<Route exact path='/connect/fieldtime' render={props => { return (<FieldTimeLogin {...props} token={token} user={user} setUser={setUser} />) }} />))} />
          <Route exact path='/crew-management/crew-member/:id' render={() => (!this.hasPolicy('Crew Management') ? (<Redirect exact to='/' />) : (<Route exact path='/crew-management/crew-member/:id' render={props => { return (<CrewMember user={user} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/crew-management/inbox' render={() => (!this.hasPolicy('Crew Management') ? (<Redirect exact to='/' />) : (<Route exact path='/crew-management/inbox' render={props => { return (<CrewManagement user={user} company={company} selectCompany={selectCompany} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/crew-management/master-list' render={() => (!this.hasPolicy('Crew Management') ? (<Redirect exact to='/' />) : (<Route exact path='/crew-management/master-list' render={props => { return (<CrewManagement user={user} company={company} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/crew-management/crews' render={() => (!this.hasPolicy('Crew Management') ? (<Redirect exact to='/' />) : (<Route exact path='/crew-management/crews' render={props => { return (<CrewManagement user={user} company={company} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/ffr/edit/:id' render={() => (!this.hasPolicy('Contract Status Reports') ? (<Redirect exact to='/' />) : (<Route exact path='/ffr/edit/:id' render={props => { return (<EditContractStatusReport user={user} company={company} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/ffr/new' render={() => (!this.hasPolicy('Contract Status Reports') ? (<Redirect exact to='/' />) : (<Route exact path='/ffr/new' render={props => { return (<NewContractStatusReport user={user} company={company} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/ffr/list' render={() => (!this.hasPolicy('Contract Status Reports') ? (<Redirect exact to='/' />) : (<Route exact path='/ffr/list' render={props => { return (<ListContractStatusReports user={user} company={company} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/crew-management' render={() => (<Redirect to='/crew-management/inbox' />)} />
          <Route exact path='/cookie-manager' render={props => { return (<ClearCookies {...props} />) }} />
          <Route exact path='/migrations/foreman' render={() => (!this.userIsAdmin() ? (<Redirect exact to='/' />) : (<Route exact path='/migrations/foreman' render={props => { return (<ForemanMigrationTool user={user} token={token} company={company} titanToken={titanToken} {...props} />) }} />))} />
          <Route exact path='/customers/:id' render={() => (!isLoggedIn ? (<Redirect exact to='/' />) : (<Route exact path='/customers/:id' render={props => { return (<CustomerMenu user={user} token={token} titanToken={titanToken} {...props} />) }} />))} />
          <Route exact path='/organization-manager/contacts' render={() => (!this.hasPolicy('Company Admin Center') ? (<Redirect exact to='/' />) : (<Route exact path='/organization-manager/contacts' render={props => { return (<CustomerAdminCenter user={user} token={token} titanToken={titanToken} {...props} />) }} />))} />
          <Route exact path='/organization-manager/contacts/:id' render={() => (!this.hasPolicy('Company Admin Center') ? (<Redirect exact to='/' />) : (<Route exact path='/organization-manager/contacts/:id' render={props => { return (<GlobalContact user={user} token={token} titanToken={titanToken} {...props} />) }} />))} />
          <Route exact path='/organization-manager/companies' render={() => (!this.hasPolicy('Company Admin Center') ? (<Redirect exact to='/' />) : (<Route exact path='/organization-manager/companies' render={props => { return (<CustomerAdminCenter user={user} token={token} titanToken={titanToken} {...props} />) }} />))} />
          <Route exact path='/organization-manager/companies/:id' render={() => (!this.hasPolicy('Company Admin Center') ? (<Redirect exact to='/' />) : (<Route exact path='/organization-manager/companies/:id' render={props => { return (<GlobalCompany user={user} token={token} titanToken={titanToken} {...props} />) }} />))} />
          <Route exact path='/organization-manager/companies/:id/merge' render={() => (!this.hasPolicy('Company Admin Center') ? (<Redirect exact to='/' />) : (<Route exact path='/organization-manager/companies/:id/merge' render={props => { return (<MergeCompanies user={user} token={token} titanToken={titanToken} {...props} />) }} />))} />
          <Route exact path='/organization-manager/tags' render={() => (!this.hasPolicy('Company Admin Center') ? (<Redirect exact to='/' />) : (<Route exact path='/organization-manager/tags' render={props => { return (<CustomerAdminCenter user={user} token={token} titanToken={titanToken} {...props} />) }} />))} />
          <Route exact path='/organization-manager' render={() => (!this.hasPolicy('Company Admin Center') ? (<Redirect exact to='/' />) : (<Route exact path='/organization-manager' render={props => { return (<OrganizationManager user={user} token={token} titanToken={titanToken} {...props} />) }} />))} />
          <Route exact path='/dashboards/estimating/titan-summary' render={() => (!this.hasPolicy('Snapshot') ? (<Redirect exact to='/' />) : (<Route exact path='/dashboards/estimating/titan-summary' render={props => { return (<EstimatingScorecard2 user={user} token={token} titanToken={titanToken} {...props} />) }} />))} />
          <Route exact path='/dashboards/estimating/bid-performance' render={() => (!this.hasPolicy('Snapshot') ? (<Redirect exact to='/' />) : (<Route exact path='/dashboards/estimating/bid-performance' component={EstimatingScorecard2} />))} />
          <Route exact path='/dashboards/estimating/bids-per-week' render={() => (!this.hasPolicy('Snapshot') ? (<Redirect exact to='/' />) : (<Route exact path='/dashboards/estimating/bids-per-week' component={EstimatingScorecard2} />))} />
          <Route exact path='/dashboards/estimating/sage-summary' render={() => (!this.hasPolicy('Snapshot') ? (<Redirect exact to='/' />) : (<Route exact path='/dashboards/estimating/sage-summary' component={EstimatingScorecard2} />))} />
          <Route exact path='/dashboards/estimating/hours-by-project' render={() => (!this.hasPolicy('Snapshot') ? (<Redirect exact to='/' />) : (<Route exact path='/dashboards/estimating/hours-by-project' component={EstimatingScorecard2} />))} />
          <Route exact path='/dashboards/estimating' render={() => (!this.hasPolicy('Snapshot') ? (<Redirect exact to='/' />) : (<Redirect exact to='/dashboards/estimating/titan-summary' />))} />
          <Route exact path='/deliveries/:id' render={() => (!this.hasPolicy('Deliveries') ? (<Redirect exact to='/' />) : (<Route exact path='/deliveries/:id' component={EditDelivery} />))} />
          <Route exact path='/deliveries' render={() => (!this.hasPolicy('Deliveries') ? (<Redirect exact to='/' />) : (<Route exact path='/deliveries' component={Deliveries} />))} />
          <Route exact path='/documentation/:id' render={() => (!isLoggedIn ? (<Redirect exact to='/' />) : (<Route exact path='/documentation/:id' render={props => { return (<ViewDocumentation {...props} />) }} />))} />
          <Route exact path='/documentation' render={() => (!isLoggedIn ? (<Redirect exact to='/' />) : (<Route exact path='/documentation' render={props => { return (<AllDocumentation {...props} />) }} />))} />
          <Route exact path='/employee-lists' render={() => (!this.hasPolicy('Employee Lists') ? (<Redirect exact to='/' />) : (<Route exact path='/employee-lists' component={EmployeeLists} />))} />
          <Route exact path='/equality-fund/:id' render={() => (!this.hasPolicy('Equality Fund') ? (<Redirect exact to='/' />) : (<Route exact path='/equality-fund/:id' render={props => { return (<SingleJobEF {...props} company={company} user={user} darkTheme={darkTheme} />) }} />))} />
          <Route exact path='/equality-fund' render={() => (!this.hasPolicy('Equality Fund') ? (<Redirect exact to='/' />) : (<Route exact path='/equality-fund' render={props => { return (<EqualityFundJobs {...props} user={user} />) }} />))} />
          <Route exact path='/feedback' render={() => (!isLoggedIn ? (<Redirect exact to='/' />) : (<Route exact path='/feedback' component={Feedback} />))} />
          <Route exact path='/field-work-orders' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/field-work-orders' render={props => { return (<AllFieldWorkOrders company={company} user={user} timestamp={new Date().toString()} darkTheme={darkTheme} {...props} />) }} />))} />
          <Route exact path='/financials' render={() => (!this.hasPolicy('Employee Lists') ? (<Redirect exact to='/' />) : (<Route exact path='/financials' render={props => { return (<Financials user={user} {...props} />) }} />))} />
          <Route exact path='/california-tax-rates' render={() => (!isLoggedIn ? (<Redirect exact to='/' />) : (<Route exact path='/california-tax-rates' component={TaxDisplay} />))} />
          <Route exact path='/field-pipeline/authentication-router' render={() => (!this.hasPolicy('Field Pipeline') ? (<Redirect exact to='/' />) : (<Route exact path='/field-pipeline/authentication-router' render={props => { return (<AuthenticationRouter user={user} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/field-pipeline/foreman' render={() => (!this.hasPolicy('Field Pipeline') ? (<Redirect exact to='/' />) : (<Route exact path='/field-pipeline/foreman' render={props => { return (<ForemanHome selectCompany={selectCompany} company={company} user={user} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/field-pipeline/off' render={() => (!this.hasPolicy('Field Pipeline') ? (<Redirect exact to='/' />) : (<Route exact path='/field-pipeline/off' component={OfficeHome} />))} />
          <Route exact path='/field-pipeline/:companyId/:jobNumber/field-work-orders/:id' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/field-pipeline/:companyId/:jobNumber/field-work-orders/:id' render={props => { return (<FieldWorkOrder {...props} user={user} darkTheme={darkTheme} />) }} />))} />
          <Route exact path='/field-pipeline/:companyId/:jobNumber/field-work-orders/:id/printable' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/field-pipeline/:companyId/:jobNumber/field-work-orders/:id/printable' render={props => { return (<FieldWorkOrderPrintable {...props} user={user} darkTheme={darkTheme} />) }} />))} />
          <Route exact path='/field-pipeline/:companyId/:jobNumber/field-work-orders' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/field-pipeline/:companyId/:jobNumber/field-work-orders' render={props => { return (<FieldWorkOrders user={user} {...props} />) }} />))} />
          <Route exact path='/field-pipeline/:companyId/:jobNumber/forms/field-work-order' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/field-pipeline/:companyId/:jobNumber/forms/field-work-order' component={FWOForm} />))} />
          <Route exact path='/fieldops-dashboard/:dept/:template' render={() => (!isLoggedIn ? (<Redirect exact to='/' />) : (<Route exact path='/fieldops-dashboard/:dept/:template' render={props => { return (<FieldOpsDashboard titanToken={titanToken} darkTheme={darkTheme} user={user} selectedCompany={selectedCompany} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/fieldops-dashboard/:dept' render={() => (!isLoggedIn ? (<Redirect exact to='/' />) : (<Route exact path='/fieldops-dashboard/:dept' render={props => { return (<FieldOpsDashboard titanToken={titanToken} darkTheme={darkTheme} user={user} selectedCompany={selectedCompany} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/fieldops-dashboard' render={() => (!isLoggedIn ? (<Redirect exact to='/' />) : (<Route exact path='/fieldops-dashboard' render={props => { return (<FieldOpsDashboard titanToken={titanToken} darkTheme={darkTheme} user={user} selectedCompany={selectedCompany} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/fieldtime' render={() => (!this.hasPolicy('Field Time') ? (<Redirect exact to='/' />) : (<Route exact path='/fieldtime' render={props => { return (<FieldTime fieldTimeToken={fieldTimeToken} darkTheme={darkTheme} user={user} selectedCompany={selectedCompany} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/fieldtime/employee-reports' render={() => (!this.userIsAdmin() ? (<Redirect exact to='/' />) : (<Route exact path='/fieldtime/employee-reports' render={props => { return (<FieldTimeEmployeeReports token={token} darkTheme={darkTheme} user={user} selectedCompany={selectedCompany} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/fieldtime/accounts' render={() => (!this.userIsAdmin() ? (<Redirect exact to='/' />) : (<Route exact path='/fieldtime/accounts' render={props => { return (<FieldTimeAccounts fieldTimeToken={fieldTimeToken} darkTheme={darkTheme} user={user} selectedCompany={selectedCompany} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/fieldtime/accounts/:id' render={() => (!this.userIsAdmin() ? (<Redirect exact to='/' />) : (<Route exact path='/fieldtime/accounts/:id' render={props => { return (<FieldTimeUser fieldTimeToken={fieldTimeToken} darkTheme={darkTheme} user={user} selectedCompany={selectedCompany} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/fieldtime/charges/:jobNumber' render={() => (!this.hasPolicy('Field Time') ? (<Redirect exact to='/' />) : (<Route exact path='/fieldtime/charges/:jobNumber' render={props => { return (<FieldTimeChargeHistory user={user} company={company} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/fieldtime/:office/timecards' render={() => (!this.hasPolicy('Field Time') ? (<Redirect exact to='/' />) : (<Route exact path='/fieldtime/:office/timecards' render={props => { return (<FieldTimeTimecardIndex token={token} fieldTimeToken={fieldTimeToken} darkTheme={darkTheme} user={user} selectedCompany={selectedCompany} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/fieldtime/:office/timecards/:id' render={() => (!this.hasPolicy('Field Time') ? (<Redirect exact to='/' />) : (<Route exact path='/fieldtime/:office/timecards/:id' render={props => { return (<TCReview fieldTimeToken={fieldTimeToken} darkTheme={darkTheme} user={user} selectedCompany={selectedCompany} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/forgot-password' component={ForgotPassword} />
          <Route exact path='/health' render={() => (!isLoggedIn ? (<Redirect exact to='/' />) : (<Route exact path='/health' render={props => { return (<HealthCheck titanToken={titanToken} darkTheme={darkTheme} user={user} selectedCompany={selectedCompany} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/health-reports' render={() => (!this.userIsAdmin() ? (<Redirect exact to='/' />) : (<Route exact path='/health-reports' render={props => { return (<HealthReportIndex titanToken={titanToken} darkTheme={darkTheme} user={user} selectedCompany={selectedCompany} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/health-reports/failed-cc-events' render={() => (!this.userIsAdmin() ? (<Redirect exact to='/' />) : (<Route exact path='/health-reports/failed-cc-events' render={props => { return (<CostCodeFailedEvents titanToken={titanToken} darkTheme={darkTheme} user={user} selectedCompany={selectedCompany} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/health-reports/inspect-events' render={() => (!this.userIsAdmin() ? (<Redirect exact to='/' />) : (<Route exact path='/health-reports/inspect-events' render={props => { return (<EventInspector titanToken={titanToken} darkTheme={darkTheme} user={user} selectedCompany={selectedCompany} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/health-reports/cached-job-numbers' render={() => (!this.userIsAdmin() ? (<Redirect exact to='/' />) : (<Route exact path='/health-reports/cached-job-numbers' render={props => { return (<CachedJobNumbersReport titanToken={titanToken} darkTheme={darkTheme} user={user} selectedCompany={selectedCompany} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/knowledge-center/supported-browsers' component={ArticleSupportedBrowsers} />
          <Route exact path='/knowledge-center/webpage-wont-load' component={ArticleWebWontLoad} />
          <Route exact path='/knowledge-center' render={() => (!isLoggedIn ? (<Redirect exact to='/' />) : (<Route exact path='/knowledge-center' component={KnowledgeCenter} />))} />
          <Route exact path='/jsr-portal/:tsCompany/:jobNumber' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/jsr-portal/:tsCompany/:jobNumber' render={props => { return (<JSRReports user={user} {...props} />) }} />))} />
          <Route exact path='/jsr-portal/:id' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/jsr-portal/:id' render={props => { return (<JSRReport user={user} token={token} {...props} />) }} />))} />
          <Route exact path='/jsr-portal' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/jsr-portal' render={props => { return (<JSRPortal company={company} user={user} timestamp={new Date().toString()} darkTheme={darkTheme} {...props} />) }} />))} />
          <Route exact path='/job-safety-sheets/:companyId/:jobNumber' render={() => (!this.hasPolicy('File Manager') ? (<Redirect exact to='/' />) : (<Route exact path='/job-safety-sheets/:companyId/:jobNumber' render={props => { return (<JobSafetySheets user={user} token={token} {...props} />) }} />))} />
          <Route exact path='/job-updates/:id' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/job-updates/:id' render={props => { return (<JobUpdate user={user} {...props} />) }} />))} />
          <Route exact path='/job-updates' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/job-updates' render={props => { return (<JobUpdates user={user} darkTheme={darkTheme} {...props} />) }} />))} />
          <Route exact path='/job-data/:company_id/:job_number/cost-codes' render={() => (!this.hasPolicy('Job Data') ? (<Redirect to='/login' />) : (<Route path='/job-data/:company_id/:job_number/cost-codes' render={props => { return (<CostCodes user={user} selectedCompany={selectedCompany} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/job-data/:company_id/:job_number/labor-transactions' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/job-data/:company_id/:job_number/labor-transactions' render={props => { return (<Transactions user={user} selectedCompany={selectedCompany} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/job-data/:company_id/:job_number/fieldtime-dashboard' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/job-data/:company_id/:job_number/fieldtime-dashboard' render={props => { return (<FieldtimeDashboard user={user} selectedCompany={selectedCompany} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/job-data/:company_id/:job_number/job-cost-summary-report' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/job-data/:company_id/:job_number/job-cost-summary-report' render={props => { return (<JobCostSummaryReport company={company} user={user} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/job-data/:job_id/detailing-forecast/:id' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/job-data/:job_id/detailing-forecast/:id' render={props => { return (<DetailingForecast company={company} user={user} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/job-data/:job_id/detailing-forecast-log' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/job-data/:job_id/detailing-forecast-log' render={props => { return (<DetailingForecastLog company={company} user={user} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/job-data/:id' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/job-data/:id' render={props => { return (<JobDataView company={company} user={user} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/job-data' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/job-data' render={props => { return (<JobData company={company} user={user} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/job-cost-summary-reports' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/job-cost-summary-reports' render={props => { return (<JobCostSummaryIndex company={company} user={user} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/login' render={() => (isLoggedIn ? (<Redirect exact to='/' />) : (<Route exact path='/login' render={props => { return (<Login selectCompany={selectCompany} company={company} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/logout' render={() => (!isLoggedIn ? (<Redirect exact to='/' />) : (<Route exact path='/logout' render={props => { return (<Logout {...props} setUser={setUser} selectCompany={selectCompany} timestamp={new Date().toString()} />) }} />))} />
          <Route exact path='/manload-schedules' render={() => (!this.hasPolicy('My Data') ? (<Redirect exact to='/' />) : (<Route exact path='/manload-schedules' render={props => { return (<ManHourForecastsAll {...props} user={user} />) }} />))} />
          <Route exact path='/manload-schedules/:id' render={() => (!this.hasPolicy('My Data') ? (<Redirect exact to='/' />) : (<Route exact path='/manload-schedules/:id' render={props => { return (<ManHourForecastsView {...props} user={user} />) }} />))} />
          <Route exact path='/manload-schedules/:id/burn-report' render={() => (!this.hasPolicy('My Data') ? (<Redirect exact to='/' />) : (<Route exact path='/manload-schedules/:id/burn-report' render={props => { return (<ManHourBurnReport {...props} user={user} selectedCompany={selectedCompany} />) }} />))} />
          <Route exact path='/manload-schedules/:id/events' render={() => (!this.hasPolicy('My Data') ? (<Redirect exact to='/' />) : (<Route exact path='/manload-schedules/:id/events' render={props => { return (<ManHourEventLog {...props} user={user} />) }} />))} />
          <Route exact path='/manload-insights' render={() => (!this.hasPolicy('My Data') ? (<Redirect exact to='/' />) : (<Route exact path='/manload-insights' render={props => { return (<ManloadInsights {...props} user={user} />) }} />))} />
          <Route exact path='/my-data/:dept/cloud-change-orders' render={() => (!this.hasPolicy('My Data') ? (<Redirect exact to='/' />) : (<Route exact path='/my-data/:dept/cloud-change-orders' render={props => { return (<CloudChangeOrders {...props} user={user} />) }} />))} />
          <Route exact path='/my-data/:dept/cloud-change-orders/:jobNumber' render={() => (!this.hasPolicy('My Data') ? (<Redirect exact to='/' />) : (<Route exact path='/my-data/:dept/cloud-change-orders/:jobNumber' render={props => { return (<SelectedJobCloudCO {...props} user={user} />) }} />))} />
          <Route exact path='/my-data/:dept/cloud-change-orders/:jobNumber/:coId' render={() => (!this.hasPolicy('My Data') ? (<Redirect exact to='/' />) : (<Route exact path='/my-data/:dept/cloud-change-orders/:jobNumber/:coId' render={props => { return (<ChangeOrderView {...props} user={user} />) }} />))} />
          <Route exact path='/co-estimates/:id' render={() => !this.hasPolicy('Pricing Table') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/co-estimates/:id'} render={props => { return (<BudgetBuilder {...props} user={user} company={company} />) }} />)} />
          <Route exact path='/co-estimates/:id/format' render={() => !this.hasPolicy('Pricing Table') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/co-estimates/:id/format'} render={props => { return (<ChangeOrderFormatter {...props} user={user} company={company} />) }} />)} />
          <Route exact path='/my-data/sage-change-orders' render={() => (!this.hasPolicy('My Data') ? (<Redirect exact to='/' />) : (<Route exact path='/my-data/sage-change-orders' render={props => { return (<SageChangeOrders {...props} user={user} company={company} />) }} />))} />
          <Route exact path='/my-data/shop/tooltrack' render={() => (!this.hasPolicy('hilti') ? (<Redirect exact to='/' />) : (<Route exact path='/my-data/shop/tooltrack' render={props => { return (<HiltiList darkTheme={darkTheme} user={user} {...props} />) }} />))} />
          <Route exact path='/my-data/shop/tooltrack/:id' render={() => (!this.hasPolicy('hilti') ? (<Redirect exact to='/' />) : (<Route exact path='/my-data/shop/tooltrack/:id' render={props => { return (<HiltiViewSingle darkTheme={darkTheme} user={user} {...props} />) }} />))} />
          <Route exact path='/my-data/:dept' render={() => (!this.hasPolicy('My Data') ? (<Redirect exact to='/' />) : (<Route exact path='/my-data/:dept' render={props => { return (<MyData user={user} company={company} timestamp={new Date().toString()} darkTheme={darkTheme} {...props} />) }} />))} />
          <Route exact path='/my-data' render={() => (!this.hasPolicy('My Data') ? (<Redirect exact to='/' />) : (<Route exact path='/my-data' render={props => { return (<DeptSelect user={user} company={company} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/reports/:dept/titan-base-sales' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/reports/:dept/titan-base-sales' component={TitanBaseSales} />))} />
          <Route exact path='/reports/scorecards/:dept' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/reports/scorecards/:dept' render={props => { return (<DeptScorecard user={user} company={company} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/reports/scorecards/:dept/:employee' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/reports/scorecards/:dept/:employee' render={props => { return (<DeptScorecard user={user} company={company} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/resource-tracking' render={() => (!this.hasPolicy('Snapshot') ? (<Redirect exact to='/' />) : (<Route exact path='/resource-tracking' component={Resources} />))} />
          <Route exact path='/receiving-log/new' render={() => !this.hasPolicy('Receiving Log', 'receiver') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/receiving-log/new'} render={props => { return (<ReceivingLogNew {...props} darkTheme={darkTheme} company={company} user={user} />) }} />)} />
          <Route exact path='/receiving-log/:id' render={() => !this.hasPolicy('Receiving Log') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/receiving-log/:id'} render={props => { return (<ReceivingLogEdit {...props} darkTheme={darkTheme} company={company} user={user} />) }} />)} />
          <Route exact path='/receiving-log' render={() => !this.hasPolicy('Receiving Log') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/receiving-log'} render={props => { return (<ReceivingLogMain {...props} darkTheme={darkTheme} company={company} user={user} />) }} />)} />
          <Route exact path='/requisition-center/new' render={() => !this.hasPolicy('Requisitions') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/requisition-center/new'} render={props => { return (<NewRequisition {...props} darkTheme={darkTheme} company={company} user={user} timestamp={new Date().toString()} />) }} />)} />
          <Route exact path='/requisition-center/:id' render={() => !this.hasPolicy('Requisitions') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/requisition-center/:id'} render={props => { return (<EditRequisition {...props} darkTheme={darkTheme} company={company} user={user} timestamp={new Date().toString()} />) }} />)} />
          <Route exact path='/requisition-center' render={() => { return !this.hasPolicy('Requisitions') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/requisition-center'} render={props => { return (<RequisitionCenterList {...props} darkTheme={darkTheme} company={company} user={user} timestamp={new Date().toString()} />) }} />) }} />
          <Route exact path='/return-log/new' render={() => !this.hasPolicy('Receiving Log', 'receiver') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/return-log/new'} render={props => { return (<NewReturnItem {...props} darkTheme={darkTheme} company={company} user={user} />) }} />)} />
          <Route exact path='/return-log/:id' render={() => !this.hasPolicy('Receiving Log') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/return-log/:id'} render={props => { return (<EditReturnItem {...props} darkTheme={darkTheme} company={company} user={user} />) }} />)} />
          <Route exact path='/return-log' render={() => !this.hasPolicy('Receiving Log') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/return-log'} render={props => { return (<ReturnLog {...props} darkTheme={darkTheme} company={company} user={user} />) }} />)} />
          <Route exact path='/rm/employee-manager' render={() => !this.hasPolicy('Risk Management Employee Manager') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/rm/employee-manager'} render={props => { return (<RiskManagementEmployeeIndex {...props} darkTheme={darkTheme} company={company} user={user} />) }} />)} />
          <Route exact path='/rm/forms' render={() => !this.hasPolicy('Job Data') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/rm/forms'} render={props => { return (<RiskManagementForms {...props} darkTheme={darkTheme} company={company} user={user} />) }} />)} />
          <Route exact path='/rm/hr-tickets' render={() => !this.hasPolicy('Risk Management Employee Manager') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/rm/hr-tickets'} render={props => { return (<HRTickets {...props} darkTheme={darkTheme} company={company} user={user} />) }} />)} />
          <Route exact path='/rm/jobsite-inspection-requests' render={() => !this.hasPolicy('Risk Management Employee Manager') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/rm/jobsite-inspection-requests'} render={props => { return (<JobsiteInspectionRequestIndex {...props} darkTheme={darkTheme} company={company} user={user} />) }} />)} />
          <Route exact path='/rm/weekly-jobsite-inspection/success' render={() => !this.hasPolicy('Job Data') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/rm/weekly-jobsite-inspection/success'} render={props => { return (<JobsiteSafetyReportSuccessPage {...props} darkTheme={darkTheme} company={company} user={user} />) }} />)} />
          <Route exact path='/rm/weekly-jobsite-inspection' render={() => !this.hasPolicy('Job Data') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/rm/weekly-jobsite-inspection'} render={props => { return (<JobsiteSafetyReport {...props} darkTheme={darkTheme} company={company} user={user} />) }} />)} />
          {/* Toolbox Talk report permissions are all managed from within each page */}
          <Route exact path='/rm/toolbox/templates/:id' render={() => !this.hasPolicy('Risk Management Employee Manager') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/rm/toolbox/templates/:id'} render={props => { return (<ToolboxTalkTemplateView {...props} darkTheme={darkTheme} company={company} user={user} />) }} />)} />
          <Route exact path='/rm/toolbox/templates' render={() => !this.hasPolicy('Risk Management Employee Manager') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/rm/toolbox/templates'} render={props => { return (<ToolboxTalkTemplateManager {...props} darkTheme={darkTheme} company={company} user={user} />) }} />)} />
          <Route exact path='/rm/toolbox/:id' render={() => !isLoggedIn ? (<Redirect exact to={'/'} />) : (<Route exact path={'/rm/toolbox/:id'} render={props => { return (<ToolboxTalkForm {...props} darkTheme={darkTheme} company={company} user={user} />) }} />)} />
          <Route exact path='/rm/toolbox' render={() => !this.hasPolicy('Risk Management Employee Manager') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/rm/toolbox'} render={props => { return (<ToolboxTalkRequests {...props} darkTheme={darkTheme} company={company} user={user} />) }} />)} />
          <Route exact path='/sales-graph' render={() => (!this.hasPolicy('Bid Board') ? (<Redirect exact to='/' />) : (<Route exact path='/sales-graph' render={props => { return (<SalesGraph company={company} user={user} timestamp={new Date().toString()} darkTheme={darkTheme} {...props} />) }} />))} />
          <Route exact path='/sales-graph/base-sales' render={() => (!this.hasPolicy('Bid Board') ? (<Redirect exact to='/' />) : (<Route exact path='/sales-graph/base-sales' render={props => { return (<TitanList company={company} user={user} timestamp={new Date().toString()} darkTheme={darkTheme} {...props} />) }} />))} />
          <Route exact path='/sales-graph/co-list' render={() => (!this.hasPolicy('Bid Board') ? (<Redirect exact to='/' />) : (<Route exact path='/sales-graph/co-list' render={props => { return (<COList company={company} user={user} timestamp={new Date().toString()} darkTheme={darkTheme} {...props} />) }} />))} />
          <Route exact path='/scorecard/:dept' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/scorecard/:dept' render={props => { return (<Scorecard user={user} company={company} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/scorecard/:dept/:employee' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/scorecard/:dept/:employee' render={props => { return (<Scorecard user={user} company={company} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/service/bid-manager' render={() => (!this.hasAccessTo([12], ['Service']) ? (<Redirect exact to='/' />) : (<Route exact path='/service/bid-manager' render={props => { return (<ServiceBidManager user={user} company={company} titanToken={titanToken} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/super-reports' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/super-reports' render={props => { return (<SuperReportsMain darkTheme={darkTheme} user={user} selectedCompany={selectedCompany} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/tandem-report' render={() => (!isLoggedIn ? (<Redirect exact to='/' />) : (<Route exact path='/tandem-report' render={props => { return (<TandemJobList darkTheme={darkTheme} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/titan' render={() => (!isLoggedIn ? (<Redirect exact to='/' />) : (<Route exact path='/titan' render={props => { return (<TitanModuleList darkTheme={darkTheme} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/bid-strategy-team-approvals' render={() => (!isLoggedIn ? (<Redirect exact to='/' />) : (<Route exact path='/bid-strategy-team-approvals' render={props => { return (<TitanApprovalReviewIndex user={user} darkTheme={darkTheme} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/pricing-table' render={() => !this.hasPolicy('Pricing Table') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/pricing-table'} render={props => { return (<PMVersionsList {...props} darkTheme={darkTheme} company={company} user={user} />) }} />)} />
          <Route exact path='/pricing-table/:id' render={() => !this.hasPolicy('Pricing Table') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/pricing-table/:id'} render={props => { return (<SinglePMView {...props} darkTheme={darkTheme} company={company} user={user} />) }} />)} />
          <Route exact path='/cloud-estimates' render={() => !this.hasPolicy('Pricing Table') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/cloud-estimates'} render={props => { return (<EstimateLogs {...props} user={user} company={company} />) }} />)} />
          <Route exact path='/cloud-estimates/:id' render={() => !this.hasPolicy('Pricing Table') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/cloud-estimates/:id'} render={props => { return (<BudgetBuilder {...props} user={user} company={company} />) }} />)} />
          <Route exact path='/estimate-directory/:dept' render={() => !this.hasPolicy('Pricing Table') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/estimate-directory/:dept'} render={props => { return (<EstimateDirectory {...props} user={user} company={company} />) }} />)} />
          <Route exact path='/pricing-template/:dept' render={() => !this.hasPolicy('Pricing Table') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/pricing-template/:dept'} render={props => { return (<PTDirectory {...props} user={user} company={company} />) }} />)} />
          <Route exact path='/pricing-template/:dept/:region' render={() => !this.hasPolicy('Pricing Table') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/pricing-template/:dept/:region'} render={props => { return (<PricingTemplates {...props} darkTheme={darkTheme} company={company} user={user} />) }} />)} />
          <Route exact path='/pricing-table-legacy' render={() => !this.hasPolicy('Pricing Table') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/pricing-table-legacy'} render={props => { return (<PMVersionsList {...props} darkTheme={darkTheme} company={company} user={user} />) }} />)} />
          <Route exact path='/pricing-table-legacy/:id' render={() => !this.hasPolicy('Pricing Table') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/pricing-table-legacy/:id'} render={props => { return (<SinglePMView {...props} darkTheme={darkTheme} company={company} user={user} />) }} />)} />
          <Route exact path='/labor-template/:dept' render={() => !this.hasPolicy('Labor Template') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/labor-template/:dept'} render={props => { return (<LaborTemplates {...props} darkTheme={darkTheme} company={company} user={user} />) }} />)} />
          <Route exact path='/pull-stock' render={() => (!this.hasAccessTo([12], ['Purchasing']) ? (<Redirect exact to='/' />) : (<Route exact path={'/pull-stock'} render={props => { return (<PullStockList {...props} titanToken={titanToken} darkTheme={darkTheme} company={company} user={user} />) }} />))} />
          <Route exact path='/sync-status' render={() => (!this.userIsAdmin() ? (<Redirect exact to='/' />) : (<Route exact path='/sync-status' render={props => { return (<SyncStatus darkTheme={darkTheme} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/unlock-request/:key' render={props => { return (<UnlockRequest {...props} />) }} />
          <Route exact path='/utilities/mass-texts' render={() => (!this.hasAccessTo([12], ['Help Desk']) ? (<Redirect exact to='/' />) : (<Route exact path='/utilities/mass-texts' render={props => { return (<MassTextMessenger {...props} user={user} />) }} />))} />
          <Route exact path='/vendor-list' render={() => (!this.hasPolicy('My Data') ? (<Redirect exact to='/' />) : (<Route exact path='/vendor-list' render={props => { return (<VendorList {...props} user={user} company={company} />) }} />))} />
          <Route exact path='/watchtower' render={() => (!this.hasPolicy('Job Data') ? (<Redirect exact to='/' />) : (<Route exact path='/watchtower' render={props => { return (<Watchtower user={user} company={company} titanToken={titanToken} timestamp={new Date().toString()} {...props} />) }} />))} />
          <Route exact path='/webhooks' render={() => (!this.userIsAdmin() ? (<Redirect exact to='/' />) : (<Route exact path='/webhooks' render={props => { return (<WebhookList {...props} user={user} company={company} />) }} />))} />
          <Route exact path='/webhooks/:interface' render={() => (!this.userIsAdmin() ? (<Redirect exact to='/' />) : (<Route exact path='/webhooks/:interface' render={props => { return (<WebhookList {...props} user={user} company={company} />) }} />))} />
          <Route exact path='/workpools' render={() => !this.hasPolicy('Work Pool') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/workpools'} render={props => { return (<WorkPoolsList {...props} user={user} company={company} />) }} />)} />
          <Route exact path='/workpools/:id' render={() => !this.hasPolicy('Work Pool') ? (<Redirect exact to={'/'} />) : (<Route exact path={'/workpools/:id'} render={props => { return (<WorkPoolsView {...props} user={user} company={company} />) }} />)} />
          <Route exact path='/' render={() => (!isLoggedIn ? (<Redirect exact to='/' />) : (<Route path='/' render={props => { return (<Dashboard {...props} user={user} darkTheme={darkTheme} />) }} />))} />
          <Route render={() => <ErrorPage documentTitle={'Error 404 (Page not found)'} heading={'404 Error'} title={'Page not found'} description={'You can either go back to the previous page or the homepage'} redirectionLink="/" linkText="Back To Home" />} />
        </Switch>
      </div>
    )
  }

  userIsLoggedIn () {
    if (this.props.loggedIn) {
      return true
    } else {
      return false
    }
  }

  userIsAdmin () {
    const { user, isLoggedIn } = this.props
    if (user && user.admin && isLoggedIn) {
      return true
    } else {
      return false
    }
  }

  hasPolicy (app, level) {
    const { user, isLoggedIn } = this.props
    if (user && isLoggedIn) {
      let hasPolicy = false
      // console.table(user._embedded.policies)
      user._embedded.policies.map(policy => {
        if (String(policy.application).toLowerCase() === String(app).toLowerCase()) {
          hasPolicy = true
          if (level) {
            if (policy.name !== level) hasPolicy = false
          }
        }
      })
      return hasPolicy
    } else {
      return false
    }
  }

  hasAccessTo (companies, departments) {
    let bool = false
    const { user, isLoggedIn } = this.props
    if (isLoggedIn) {
      user._embedded.companies.map(company => {
        companies.map(n => {
          if (n === company.ts_id) bool = true
        })
      })
    }
    if (bool) {
      let deptBool
      user._embedded.departments.map(dept => {
        departments.map(string => {
          if (string === dept.name) deptBool = true
        })
      })
      if (!deptBool) bool = false
    }
    return bool
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.user === null && nextProps.user !== null && this.state.preventReload === false) {
      log.debug('user added to parent state')
      const user = nextProps.user
      this.setState({
        isLoggedIn: user.active,
        isAdmin: user.admin,
        preventReload: true
      })
    }
  }
}

Router.propTypes = {
  token: PropTypes.string,
  darkTheme: PropTypes.bool.isRequired,
  darkThemeEnabled: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    cm_id: PropTypes.string,
    dashboard_view: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    email_notifications: PropTypes.bool.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    suffix: PropTypes.string,
    full_name: PropTypes.string.isRequired,
    last_activity: PropTypes.string.isRequired,
    phone: PropTypes.string,
    text_messages: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    ui_notifications: PropTypes.bool.isRequired,
    _embedded: PropTypes.shape({
      companies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          created_at: PropTypes.string.isRequired,
          updated_at: PropTypes.string.isRequired,
          ts_id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired
        })
      ).isRequired,
      connected_accounts: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          created_at: PropTypes.string.isRequired,
          updated_at: PropTypes.string.isRequired,
          account: PropTypes.string.isRequired,
          connected: PropTypes.bool.isRequired,
          token: PropTypes.string
        })
      ).isRequired,
      departments: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          created_at: PropTypes.string.isRequired,
          updated_at: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          company_id: PropTypes.string.isRequired
        })
      ).isRequired,
      policies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          created_at: PropTypes.string.isRequired,
          updated_at: PropTypes.string.isRequired,
          application: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          description: PropTypes.string
        })
      ).isRequired
    })
  })
}

export default Router
