const darkTheme = {
  tabs: {
    backgroundColor: 'rgb(229, 229, 229)'
  },
  palette: {
    canvasColor: '#21252d',
    textColor: '#b0b6be',
    alternateTextColor: '#fff',
    borderColor: '#141618',
    accent1Color: '#ff4081',
    accent2Color: '#f50057',
    accent3Color: 'rgba(255, 255, 255, 0.3)',
    clockCircleColor: 'rgba(255, 255, 255, 0.12)',
    disabledColor: 'rgba(255, 255, 255, 0.3)',
    pickerHeaderColor: 'rgba(255, 255, 255, 0.12)',
    primary1Color: '#0097a7',
    primary2Color: '#0097a7',
    primary3Color: '#757575',
    secondaryTextColor: 'rgba(255, 255, 255, 0.7)'
  }
}

export default darkTheme
