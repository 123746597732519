
// import Footer from './components/footer/Footer.jsx'
import {
  openMenuSidebar,
  dispatchSnackbar,
  // getNotifications,
  addNotification
} from '../../actions/UserInterfaceActions.jsx'

import {
  addUserToState
} from '../../actions/AuthActions.jsx'

function mapDispatchToProps (dispatch) {
  return {
    openMenuSidebar: () => {
      dispatch(openMenuSidebar())
    },
    dispatchSnackbar: obj => {
      dispatch(dispatchSnackbar(obj))
    },
    // getNotifications: notifications => {
    //   dispatch(getNotifications(notifications))
    // },
    addNotification: (obj) => {
      dispatch(addNotification(obj))
    },
    addUserToState: (user) => {
      dispatch(addUserToState(user))
    }
  }
}

export default mapDispatchToProps
