function _onScroll (e) {
  const n = document.getElementById('notificationSidebar').parentElement
  const bottom = (n.clientHeight + n.scrollTop) >= n.scrollHeight
  if (bottom && this.state.bottom === false) {
    this.setState({
      isLoadingMore: true,
      bottom: true
    }, async () => {
      await this.getNotifications((this.state.page + 1))
    })
  }
}

export default _onScroll
