function mapStateToProps (state) {
  return {
    entryUrl: state.userAuth.entryUrl,
    isAuthenticated: state.userAuth.isAuthenticated,
    fullName: state.userAuth.full_name,
    user: state.userAuth.user,
    notifications: state.userInterface.notifications,
    notificationCount: state.userInterface.notificationCount,
    unreadNotifications: state.userInterface.unreadNotifications
  }
}

export default mapStateToProps
