import moment from 'moment'
import cookies from './cookies'
import { AUTHTOKEN } from './constants'

const backboneUtils = {
  rootDir: global.__base = String(__dirname),
  checkTokenExpire: () => {
    const token = window.localStorage.tokenExpire
    const today = new Date()
    const td = new Date(token)
    if (td < today) {
      // reset localStorage if token in localStorage expired
      window.localStorage.clear()
      return false
    } else {
      return true
    }
  },
  checkTokenAgainstServer: () => {
    // check token in local storage against the server
    return cookies.get(AUTHTOKEN)
  },
  checkIfAuthenticated: () => {
    if (backboneUtils.checkTokenExpire() && backboneUtils.checkTokenAgainstServer()) {
      return true
    } else {
      return false
    }
  },
  wait: (ms) => {
    const start = new Date().getTime()
    let end = start
    while (end < start + ms) {
      end = new Date().getTime()
    }
  },
  boolean: (boolean) => {
    if (boolean === 'true') {
      return true
    } else if (boolean === 'false') {
      return false
    } else {
      return boolean
    }
  },
  toggleLoader: (boolean) => {
    if (boolean) {
      return { display: 'none' }
    } else if (boolean === false) {
      return { display: 'block' }
    } else {
      return null
    }
  },
  kickNull: (variable) => {
    if (variable === null || variable === undefined) {
      return ''
    } else {
      return variable
    }
  },
  setPageTitle: (title) => {
    if (title === null) {
      document.title = 'Loading'
    } else {
      document.title = title
    }
  },
  parseDate: (date) => {
    if (date === null || date === undefined) {
      return ''
    } else {
      const x = date

      const MM = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

      const xx = x.replace(
        /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):\d{2}(\w{3})/,
        function ($0, $1, $2, $3, $4, $5, $6) {
          return MM[$2 - 1] + ' ' + $3 + ', ' + $1 + ' - ' + $4 % 12 + ':' + $5 + (+$4 > 12 ? 'PM' : 'AM') + ' ' + $6
        }
      )
      return xx
    }
  },
  normalizePhone: (value, previousValue) => {
    if (!value) {
      return value
    }
    const onlyNumbers = value.replace(/[^\d]/g, '')
    if (!previousValue || value.length > previousValue.length) {
      // typing forward
      if (onlyNumbers.length === 3) {
        return onlyNumbers + '-'
      }
      if (onlyNumbers.length === 6) {
        return onlyNumbers.slice(0, 3) + '-' + onlyNumbers.slice(3) + '-'
      }
    }
    if (onlyNumbers.length <= 3) {
      return onlyNumbers
    }
    if (onlyNumbers.length <= 6) {
      return onlyNumbers.slice(0, 3) + '-' + onlyNumbers.slice(3)
    }
    return onlyNumbers.slice(0, 3) + '-' + onlyNumbers.slice(3, 6) + '-' + onlyNumbers.slice(6, 10)
  },
  enforceUpperCase: value => value && value.toUpperCase(),
  enforceLowerCase: value => value && value.toLowerCase(),
  enforceLessThan: otherField => (value, previousValue, allValues) => value < allValues[otherField] ? value : previousValue,
  enforceGreaterThan: otherField => (value, previousValue, allValues) => value > allValues[otherField] ? value : previousValue,
  parseDateWithMoment: (string) => {
    const date = moment(string)
    const today = moment().endOf('day') // today
    const yesterday = moment().endOf('day').add(-1, 'days') // one
    const twoDays = moment().endOf('day').add(-2, 'days') // two
    const threeDays = moment().endOf('day').add(-3, 'days') // three
    const fourDays = moment().endOf('day').add(-4, 'days') // four
    const fiveDays = moment().endOf('day').add(-5, 'days') // five
    const sixDays = moment().endOf('day').add(-6, 'days') // six

    if (date.endOf('day').diff(today) === 0) {
      return 'Today'
    } else if (date.endOf('day').diff(yesterday) === 0) {
      return 'Yesterday'
    } else if (date.endOf('day').diff(twoDays) === 0) {
      return date.format('dddd')
    } else if (date.endOf('day').diff(threeDays) === 0) {
      return date.format('dddd')
    } else if (date.endOf('day').diff(fourDays) === 0) {
      return date.format('dddd')
    } else if (date.endOf('day').diff(fiveDays) === 0) {
      return date.format('dddd')
    } else if (date.endOf('day').diff(sixDays) === 0) {
      return date.format('dddd')
    } else {
      return date.format('MMM do, YYYY')
    }
  }
}

export default backboneUtils
