/* eslint-disable no-case-declarations */
const arsenal = (
  state = {
    errorMessage: null,
    isFetching: false,
    callingAction: null,
    errorCaller: null,
    userInfo: {},
    crewMembers: [],
    crews: [],
    deleteCrewModalStatus: false,
    removeMemberModalStatus: false,
    searchData: null,
    crewMember: {},
    crewMemberAddress: {},
    crewMemberClass: {},
    crewMemberUnion: {},
    crewMemberTrade: {},
    crewMemberSatteliteUnion: {},
    crewMemberLeader: {},
    crewMemberComments: [],
    comment: '',
    submitComment: false,
    editModalOpen: false,
    crewMemberPagination: {}
  }, action) => {
  switch (action.type) {
  case 'ARSENAL_ERROR':
    const error = Object.assign({}, action.errorMessage)
    return Object.assign({}, state, {
      errorMessage: error.response.data.error,
      errorCaller: action.errorCall,
      isFetching: false
    })
  case 'FETCH_ARSENAL':
    return Object.assign({}, state, {
      isFetching: true,
      callingAction: action.callingAction
    })
  case 'USER_INFO_REQUEST':
    return Object.assign({}, state, {
      isFetching: false,
      userInfo: action.userInfo
    })
  case 'TEAM_LEADERS_REQUEST':
    return Object.assign({}, state, {
      isFetching: false,
      crewMembers: action.crewMembers
    })
  case 'CREWS_REQUEST':
    return Object.assign({}, state, {
      isFetching: false,
      crews: action.crews
    })
  case 'OPEN_DELETE_MODAL':
    return Object.assign({}, state, {
      deleteCrewModalStatus: true
    })
  case 'CLOSE_DELETE_MODAL':
    return Object.assign({}, state, {
      deleteCrewModalStatus: false
    })
  case 'OPEN_REMOVE_MODAL':
    return Object.assign({}, state, {
      removeMemberModalStatus: true
    })
  case 'CLOSE_REMOVE_MODAL':
    return Object.assign({}, state, {
      removeMemberModalStatus: false
    })
  case 'SEARCH_MEMBER':
    return Object.assign({}, state, {
      searchData: action.searchData
    })
  case 'CREW_MEMBER_REQUEST':
    return Object.assign({}, state, {
      crewMember: action.crewMember,
      crewMemberLeader: action.crewMemberLeader,
      crewMemberAddress: action.crewMemberAddress,
      crewMemberClass: action.crewMemberClass,
      crewMemberUnion: action.crewMemberUnion,
      crewMemberTrade: action.crewMemberTrade,
      crewMemberSatteliteUnion: action.crewMemberSatteliteUnion
    })
  case 'CREW_MEMBER_COMMENT':
    return Object.assign({}, state, {
      comment: action.comment,
      submitComment: action.submitComment
    })
  case 'CREW_MEMBER_COMMENTS':
    return Object.assign({}, state, {
      crewMemberComments: action.crewMemberComments
    })
  case 'EDIT_COMMENT_MODAL':
    return Object.assign({}, state, {
      editModalOpen: action.editModalOpen,
      comment: action.comment
    })
  case 'REQUESTED_CREW_MEMBERS': {
    return Object.assign({}, state, {
      isFetching: false,
      crewMembers: action.crewMembers,
      crewMemberPagination: action.crewMemberPagination
    })
  }
  default:
    return state
  }
}

export default arsenal
