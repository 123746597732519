function setTitanToken (user) {
  let titanToken = null
  user._embedded.connected_accounts.forEach(account => {
    if (account.account === 'titan') {
      window.localStorage.setItem('titanToken', account.token)
      titanToken = account.token
    }
  })
  return titanToken
}

export default setTitanToken
