import pretty from './pretty'

function log (namespace) {
  return {
    debug: message => {
      pretty(namespace, 'DEBUG', message)
    },
    info: message => {
      pretty(namespace, 'INFO', message)
    },
    warn: message => {
      pretty(namespace, 'WARN', message)
    },
    fatal: message => {
      pretty(namespace, 'FATAL', message)
    }
  }
}

export default log
