function getAll () {
  const pairs = document.cookie.split(';')
  const cookies = {}
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    cookies[(pair[0] + '').trim()] = unescape(pair.slice(1).join('='))
  }
  return cookies
}

export default getAll
