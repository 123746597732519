/* eslint-disable no-async-promise-executor */
import { BB } from '../../../lib/http'
import logger from '../../../lib/log'

const log = logger('containers:sidebars:NotificationSidebar:methods:getNotifications')

function getNotifications (page) {
  return new Promise(async (resolve, reject) => {
    try {
      if (this.props.isAuthenticated && this.state.preventLoad === false) {
        const res = await BB.get(`/notifications?page=${page}&per=${25}&order_by_many=created_at:desc`)
        const notifications = []
        let preventLoad = false
        this.state.notifications.map(notification => notifications.push(notification))
        res.data.data.notifications.map(notification => notifications.push(notification))
        if (res.data.pagination.page_count === res.data.pagination.page && this.state.sidebarOpen) preventLoad = true
        this.setState({
          notifications,
          isLoading: false,
          preventLoad,
          page: res.data.pagination.page,
          isLoadingMore: false,
          unreadCount: res.data.unread_count,
          bottom: false
        }, () => {
          this.props.unreadCount(res.data.unread_count)
          resolve()
        })
      } else {
        resolve()
      }
    } catch (err) {
      log.fatal('this.getNotifications()')
      log.fatal(`${err}`)
      this.setState({ errorSnackbar: true, errorMsg: 'Error Obtaining Notification, Try Refreshing Page' }, () => {
        reject(err)
      })
    }
  })
}

export default getNotifications
