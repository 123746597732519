/* eslint-disable no-async-promise-executor */
import cookies from '../../lib/cookies'
import { OAUTH } from '../../lib/http'
import { AUTHTOKEN, DOMAIN } from '../../lib/constants'
import { MSTHRESHOLD } from './config'
import msFromDate from './msFromDate'
import logger from '../../lib/log'

const log = logger('containers:App:handleAuthToken')

function attemptTokenRefresh () {
  return new Promise(async (resolve, reject) => {
    try {
      log.debug('get authtoken from cookies')
      const token = cookies.get(AUTHTOKEN)
      let responseToken = null
      if (token) {
        log.debug('token found in cookies')
        try {
          const details = await OAUTH.post('/info', { token })
          log.debug('validated token with OAUTH')
          responseToken = token
          this.setHTTPToken(token)
          // determine how much time is remaining till token expires
          const msRemaining = msFromDate(details.data.expires)

          // if the remaining time is less than the threshold, we need a refresh
          if (msRemaining < MSTHRESHOLD) responseToken = await this.attemptTokenRefresh(token)
        } catch (err) {
          if (err.response) {
            switch (err.response.status) {
            case 498:
              // token has expired, attempt a refresh
              responseToken = await this.attemptTokenRefresh(token) // attempt refresh
              break
            case 410:
              // delete cookie
              cookies.delete(AUTHTOKEN, process.env.NODE_ENV === 'production' ? DOMAIN : null)
              // redirect to authorize
              if (!window.location.href.includes('/authorize')) window.location.href = '/authorize'
              throw err
            default:
              throw err
            }
          } else {
            throw err
          }
        }
        resolve(responseToken)
      } else {
        log.debug('no token found')
        resolve(null)
      }
    } catch (err) {
      log.fatal(err)
      reject(err)
    }
  })
}

export default attemptTokenRefresh
