/* eslint-disable camelcase */
function UNSAFE_componentWillUpdate (nextProps, _nextState) {
  // if (nextProps.isAuthenticated && !this.state.isAuthenticated) {
  //   this.setState({
  //     isAuthenticated: true,
  //     isLoading: true,
  //     notifications: [],
  //     page: 1
  //   }, async () => {
  //     await this.getNotifications(1)
  //   })
  // }
  if (nextProps.user && !this.state.user) {
    this.setState({
      user: nextProps.user
    }, () => {
      if (this.props.user) this.socketConnection(nextProps.user.id)
    })
  }
}

export default UNSAFE_componentWillUpdate
