export const SECONDSTHRESHOLD = 10 * 60 // 600 seconds
export const MSTHRESHOLD = SECONDSTHRESHOLD * 1000 // 600,000 ms
export const NINETYDAYSASSECONDS = 90 * 86400

const config = {
  SECONDSTHRESHOLD,
  MSTHRESHOLD,
  NINETYDAYSASSECONDS
}

export default config
