import deleteCookie from './delete'

function set (key, value, seconds, domain) {
  // delete existing instances of cookie before creating a new one
  deleteCookie(key, domain)
  // now its safe to set a new cookie
  let string = `${key}=${value}`
  if (seconds) string += `; max-age=${seconds}`
  if (domain) string += `; domain=${domain}`
  string += '; path=/'
  document.cookie = string
}

export default set
