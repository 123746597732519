/* eslint-disable no-case-declarations */
// import {} from '../actions/AdminActions.jsx'

// The auth reducer. The starting state sets authentication
// based on a token being in local storage. In a real app,
// we would also want a util to check if the token is expired.
const admin = (
  state = {
    userFilter: null,
    isFetching: false,
    errorMessage: null,
    userList: [],
    user: {},
    fetchingCompanies: false,
    fetchingCompaniesError: null,
    company: {},
    companies: [],
    noAccessCompanies: [],
    fetchingDepartments: false,
    fetchingDepartmentsError: null,
    departments: [],
    noAccessDepartments: [],
    companyTree: [],
    addCompanyModalOpen: false,
    addDepartmentModalOpen: false,
    addUserSucceeded: false,
    department: {},
    appsDepartmentCannotAccess: [],
    updatingDeptApps: false,
    fetchingUsers: false,
    updatingDept: false,
    deptUsers: [],
    updateDeptMsg: null,
    updateDeptError: null,
    selectedApp: {},
    deptAppModalOpen: false,
    fetchingAppRoles: false,
    appRolesError: null,
    canAccessAppRoles: [],
    noRolesAssignedToApp: [],
    selectedRole: {}, // reset to null if something breaks
    deptAppRoles: [],
    selectedUsers: [],
    selectedDeptDropdownVal: null,
    selectedDeptDropdownName: null,
    deptUsersWithApp: [],
    userListPage: 1,
    userListPer: 1,
    userListPageCount: 1,
    searchData: [],
    userAppsCanAccess: [],
    userAppsCannotAccess: [],
    appModalOpen: false
  },
  action
) => {
  switch (action.type) {
  case 'FETCHING':
    return Object.assign({}, state, {
      isFetching: true
    })
  case 'CLEAR_FETCHING':
    return Object.assign({}, state, {
      isFetching: false
    })
  case 'CLEAR_ERROR_MESSAGE':
    return Object.assign({}, state, {
      errorMessage: null
    })
  case 'ADMIN_ERROR':
    const err = Object.assign({}, action.errorMessage)
    return Object.assign({}, state, {
      isFetching: false,
      errorMessage: err.response.data.error
    })
  case 'REQUESTED_ACTIVE_USERS':
    return Object.assign({}, state, {
      isFetching: true
    })
  case 'RECEIVED_ACTIVE_USERS':
    return Object.assign({}, state, {
      isFetching: false,
      userList: action.userList,
      userFilter: action.userFilter,
      userListPage: action.userListPage,
      userListPer: action.userListPer,
      userListPageCount: action.userListPageCount
    })
  case 'ACTIVE_USERS_ERROR':
    return Object.assign({}, state, {
      isFetching: true,
      errorMessage: action.errorMessage
    })
  case 'CLEAR_USER_LIST':
    return Object.assign({}, state, {
      userFilter: null,
      isFetching: false,
      errorMessage: null,
      userList: []
    })
  case 'REQUESTED_INACTIVE_USERS':
    return Object.assign({}, state, {
      isFetching: true
    })
  case 'RECEIVED_INACTIVE_USERS':
    return Object.assign({}, state, {
      isFetching: action.isFetching,
      userList: action.userList,
      userFilter: action.userFilter,
      userListPage: action.userListPage,
      userListPer: action.userListPer,
      userListPageCount: action.userListPageCount
    })
  case 'INACTIVE_USERS_ERROR':
    return Object.assign({}, state, {
      isFetching: action.isFetching,
      errorMessage: action.errorMessage
    })
  case 'REQUESTED_USER_DATA':
    return Object.assign({}, state, {
      isFetching: action.isFetching
    })
  case 'RECEIVED_USER_DATA':
    return Object.assign({}, state, {
      isFetching: action.isFetching,
      user: action.user
    })
  case 'FAILED_REQUESTED_USER_DATA':
    return Object.assign({}, state, {
      isFetching: action.isFetching,
      errorMessage: action.errorMessage
    })
  case 'CLEAR_USER':
    return Object.assign({}, state, {
      isFetching: false,
      user: null,
      errorMessage: null,
      fetchingCompanies: false,
      companies: [],
      fetchingDepartments: false,
      departments: []
    })
  case 'REQUEST_USER_COMPANIES':
    return Object.assign({}, state, {
      fetchingCompanies: action.fetchingCompanies
    })
  case 'RECEIVED_COMPANIES':
    return Object.assign({}, state, {
      fetchingCompanies: false
    })
  case 'SET_COMPANIES_USER_CAN_ACCESS':
    return Object.assign({}, state, {
      companies: action.companies
    })
  case 'SET_COMPANIES_USER_CANNOT_ACCESS':
    return Object.assign({}, state, {
      noAccessCompanies: action.noAccessCompanies
    })
  case 'FAILED_TO_RETRIEVE_USER_COMPANIES':
    return Object.assign({}, state, {
      fetchingCompanies: action.fetchingCompanies,
      fetchingCompaniesError: action.fetchingCompaniesError
    })
  case 'REQUEST_USER_DEPARTMENTS':
    return Object.assign({}, state, {
      fetchingDepartments: true
    })
  case 'RECEIVED_USER_DEPARTMENTS':
    return Object.assign({}, state, {
      fetchingDepartments: false
    })
  case 'SET_DEPARTMENTS_USER_CAN_ACCESS':
    return Object.assign({}, state, {
      departments: action.departments
    })
  case 'SET_DEPARTMENTS_USER_CANNOT_ACCESS':
    return Object.assign({}, state, {
      noAccessDepartments: action.noAccessDepartments
    })
  case 'FAILED_TO_RETRIEVE_USER_DEPARTMENTS':
    return Object.assign({}, state, {
      fetchingDepartments: false,
      fetchingDepartmentsError: action.fetchingDepartmentsError
    })
  case 'ADD_COMPANY_TO_USER':
    return Object.assign({}, state, {
      fetchingCompanies: action.fetchingCompanies
    })
  case 'RECEIVED_UPDATED_USER_COMPANIES':
    return Object.assign({}, state, {
      fetchingCompanies: action.fetchingCompanies,
      companies: action.companies,
      noAccessCompanies: action.noAccessCompanies
    })
  case 'ERROR_UPDATING_USER_COMPANIES':
    return Object.assign({}, state, {
      fetchingCompanies: action.fetchingCompanies,
      fetchingCompaniesError: action.fetchingCompaniesError
    })
  case 'REMOVE_COMPANY_FROM_USER':
    return Object.assign({}, state, {
      fetchingCompanies: action.fetchingCompanies
    })
  case 'ADD_DEPARTMENT_TO_USER':
    return Object.assign({}, state, {
      fetchingDepartments: action.fetchingDepartments
    })
  case 'RECEIVED_UPDATED_USER_DEPARTMENTS':
    return Object.assign({}, state, {
      fetchingDepartments: action.fetchingDepartments,
      departments: action.departments,
      noAccessDepartments: action.noAccessDepartments
    })
  case 'ERROR_UPDATING_USER_DEPARTMENTS':
    return Object.assign({}, state, {
      fetchingDepartments: action.fetchingDepartments,
      fetchingDepartmentsError: action.fetchingDepartmentsError
    })
  case 'REMOVE_DEPARTMENT_FROM_USER':
    return Object.assign({}, state, {
      fetchingDepartments: action.fetchingDepartments
    })
  case 'UPDATING_USER_DETAILS':
    return Object.assign({}, state, {
      isFetching: action.isFetching
    })
  case 'USER_UPDATE_SUCCESSFUL':
    return Object.assign({}, state, {
      isFetching: action.isFetching,
      user: action.user
    })
  case 'USER_UPDATE_FAILED':
    return Object.assign({}, state, {
      isFetching: action.isFetching,
      errorMessage: action.errorMessage
    })
  case 'REQUEST_ALL_COMPANIES':
    return Object.assign({}, state, {
      isFetching: action.isFetching
    })
  case 'RECEIVED_ALL_COMPANIES':
    return Object.assign({}, state, {
      isFetching: action.isFetching,
      companies: action.companies
    })
  case 'REQUEST_ALL_COMPANIES_ERROR':
    return Object.assign({}, state, {
      isFetching: action.isFetching,
      errorMessage: action.errorMessage
    })
  case 'REQUEST_COMPANY_TREE':
    return Object.assign({}, state, {
      isFetching: true
    })
  case 'RECEIVED_COMPANY_TREE':
    return Object.assign({}, state, {
      isFetching: false,
      companyTree: action.companyTree
    })
  case 'OPEN_ADD_COMPANY_MODAL':
    return Object.assign({}, state, {
      addCompanyModalOpen: true
    })
  case 'CLOSE_ADD_COMPANY_MODAL':
    return Object.assign({}, state, {
      addCompanyModalOpen: false
    })
  case 'OPEN_ADD_DEPARTMENT_MODAL':
    return Object.assign({}, state, {
      addDepartmentModalOpen: true
    })
  case 'CLOSE_ADD_DEPARTMENT_MODAL':
    return Object.assign({}, state, {
      addDepartmentModalOpen: false
    })
  case 'ADD_NEW_COMPANY_REQUEST':
    return Object.assign({}, state, {
      isFetching: true
    })
  case 'ADD_NEW_COMPANY_SUCCESS':
    return Object.assign({}, state, {
      isFetching: false,
      addCompanyModalOpen: false
    })
  case 'ADD_NEW_COMPANY_FAILED':
    return Object.assign({}, state, {
      isFetching: false,
      errorMessage: action.errorMessage
    })
  case 'ADD_NEW_USER_REQUEST':
    return Object.assign({}, state, {
      isFetching: true
    })
  case 'ADD_NEW_USER_SUCCESS':
    return Object.assign({}, state, {
      isFetching: false,
      user: action.user,
      addUserSucceeded: true
    })
  case 'ADD_NEW_USER_FAILED':
    return Object.assign({}, state, {
      isFetching: false,
      errorMessage: action.errorMessage
    })
  case 'REQUEST_DEPARTMENT':
    return Object.assign({}, state, {
      isFetching: true
    })
  case 'REQUEST_DEPARTMENT_SUCCESS':
    return Object.assign({}, state, {
      isFetching: false,
      department: action.department
    })
  case 'REQUEST_DEPARTMENT_FAILED':
    return Object.assign({}, state, {
      isFetching: false,
      errorMessage: action.errorMessage
    })
  case 'UPDATE_DEPT_APPS_REQUEST':
    return Object.assign({}, state, {
      updatingDeptApps: true
    })
  case 'UPDATE_DEPT_APPS_SUCCESS':
    return Object.assign({}, state, {
      updatingDeptApps: false,
      department: action.department
    })
  case 'UPDATE_DEPT_APPS_FAILED':
    return Object.assign({}, state, {
      updatingDeptApps: false,
      errorMessage: action.errorMessage
    })

  case 'REQUEST_APPS_DEPT_CANNOT_ACCESS':
    return Object.assign({}, state, {
      isFetching: true
    })
  case 'RECEIVED_APPS_DEPT_CANNOT_ACCESS':
    return Object.assign({}, state, {
      isFetching: false,
      appsDepartmentCannotAccess: action.appsDepartmentCannotAccess
    })
  case 'FAILED_REQUEST_APPS_DEPT_CANNOT_ACCESS':
    return Object.assign({}, state, {
      isFetching: false,
      errorMessage: action.errorMessage
    })
  case 'DEPT_USERS_REQUEST':
    return Object.assign({}, state, {
      fetchingUsers: true
    })
  case 'DEPT_USERS_SUCCESS':
    return Object.assign({}, state, {
      fetchingUsers: false,
      deptUsers: action.deptUsers
    })
  case 'DEPT_USERS_FAILED':
    return Object.assign({}, state, {
      fetchingUsers: false,
      errorMessage: action.errorMessage
    })
  case 'UPDATE_DEPARTMENT_REQUEST':
    return Object.assign({}, state, {
      updatingDept: true
    })
  case 'UPDATE_DEPARTMENT_SUCCESS':
    return Object.assign({}, state, {
      updatingDept: false,
      department: action.department,
      updateDeptMsg: action.updateDeptMsg
    })
  case 'UPDATE_DEPARTMENT_FAILED':
    return Object.assign({}, state, {
      updatingDept: false,
      updateDeptError: action.updateDeptError
    })
  case 'SELECTED_DEPARTMENT_APP':
    return Object.assign({}, state, {
      selectedApp: action.selectedApp
    })
  case 'TOGGLE_DEPT_APP_USERS_MODAL':
    return Object.assign({}, state, {
      deptAppModalOpen: action.deptAppModalOpen,
      fetchingAppRoles: action.fetchingAppRoles
    })
  case 'DEPT_APP_USERS_SUCCESS':
    return Object.assign({}, state, {
      fetchingAppRoles: false,
      canAccessAppRoles: action.canAccessAppRoles,
      noRolesAssignedToApp: action.noRolesAssignedToApp,
      selectedRole: action.selectedRole
    })
  case 'DEPT_APP_USERS_FAILED':
    return Object.assign({}, state, {
      fetchingAppRoles: false,
      appRolesError: action.appRolesError
    })
  case 'REMOVE_ROLE_FROM_USER_REQUEST':
    return Object.assign({}, state, {
      fetchingAppRoles: true
    })
  case 'REMOVE_ROLE_FROM_USER_SUCCESS':
    return Object.assign({}, state, {
      fetchingAppRoles: false,
      canAccessAppRoles: action.canAccessAppRoles,
      noRolesAssignedToApp: action.noRolesAssignedToApp
    })
  case 'REMOVE_ROLE_FROM_USER_FAILED':
    return Object.assign({}, state, {
      fetchingAppRoles: false,
      appRolesError: action.appRolesError
    })
  case 'DEPT_APPLICATION_ROLES_REQUEST':
    return Object.assign({}, state, {
      fetchingAppRoles: true
    })
  case 'DEPT_APPLICATION_ROLES_SUCCESS':
    return Object.assign({}, state, {
      fetchingAppRoles: false,
      deptAppRoles: action.deptAppRoles
    })
  case 'DEPT_APPLICATION_ROLES_FAILED':
    return Object.assign({}, state, {
      fetchingAppRoles: false,
      deptAppRolesError: action.deptAppRolesError
    })
  case 'SELECTED_USERS':
    return Object.assign({}, state, {
      selectedUsers: action.selectedUsers
    })
  case 'UPDATE_SELECTED_USERS_REQUEST':
    return Object.assign({}, state, {
      fetchingAppRoles: true
    })
  case 'UPDATE_SELECTED_USERS_SUCCESS':
    return Object.assign({}, state, {
      fetchingAppRoles: false,
      deptAppRoles: action.deptAppRoles
    })
  case 'UPDATE_SELECTED_USERS_FAILED':
    return Object.assign({}, state, {
      fetchingAppRoles: false,
      deptAppRolesError: action.deptAppRolesError
    })
  case 'CLEAR_SELECTED_USERS':
    return Object.assign({}, state, {
      fetchingAppRoles: false,
      deptAppRoles: action.deptAppRoles
    })
  case 'RESET_DEPT_APP_ROLES':
    return Object.assign({}, state, {
      deptAppRoles: action.deptAppRoles
    })
  case 'UPDATED_DEPT_DROPDOWN':
    return Object.assign({}, state, {
      selectedDeptDropdownVal: action.selectedDeptDropdownVal,
      selectedDeptDropdownName: action.selectedDeptDropdownName
    })
  case 'ADD_NEW_DEPARTMENT_REQUEST':
    return Object.assign({}, state, {
      isFetching: true
    })
  case 'ADD_NEW_DEPARTMENT_SUCCESS':
    return Object.assign({}, state, {
      isFetching: false,
      addDepartmentModalOpen: false
    })
  case 'ADD_NEW_DEPARTMENT_FAILED':
    return Object.assign({}, state, {
      isFetching: false,
      errorMessage: action.errorMessage
    })
  case 'GET_USERS_WITH_APP':
    return Object.assign({}, state, {
      isFetching: true
    })
  case 'SUCCESS_USERS_WITH_APP':
    return Object.assign({}, state, {
      isFetching: false,
      deptUsersWithApp: action.deptUsersWithApp
    })
  case 'FAILED_GET_USERS_WITH_APP':
    return Object.assign({}, state, {
      isFetching: false
    })
  case 'SEARCH_USER':
    return Object.assign({}, state, {
      searchData: action.searchData
    })
  case 'FILTER_USERS':
    return Object.assign({}, state, {
      userList: action.userList
    })
  case 'ERROR_FILTERING_USERS':
    return Object.assign({}, state, {
      isFetching: false,
      errorMessage: action.errorMessage
    })
  case 'USER_APPS_CAN_ACCESS':
    return Object.assign({}, state, {
      isFetching: false,
      userAppsCanAccess: action.userAppsCanAccess
    })
  case 'USER_APPS_CANNOT_ACCESS':
    return Object.assign({}, state, {
      isFetching: false,
      userAppsCannotAccess: action.userAppsCannotAccess
    })
  case 'APP_MODAL':
    return Object.assign({}, state, {
      appModalOpen: action.appModalOpen
    })
  case 'SELECTED_APP':
    return Object.assign({}, state, {
      selectedApp: action.selectedApp
    })
  case 'SELECTED_ROLE':
    return Object.assign({}, state, {
      selectedRole: action.selectedRole
    })
  default:
    return state
  }
}

export default admin
