/* eslint-disable react/prop-types */
import React from 'react'
import { connect } from 'react-redux'
import BrowserRouter from 'react-router-dom/BrowserRouter'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import Router from '../../Router.jsx'
import Header from '../../components/Header/Header.jsx'
import componentDidMount from './componentDidMount'
import componentWillUnmount from './componentWillUnmount.js'
import selectCompany from './selectCompany'
import setUser from './setUser'
import handleAuthToken from './handleAuthToken'
import attemptTokenRefresh from './attemptTokenRefresh'
import selectedCompany from './selectedCompany'
import setTitanToken from './setTitanToken'
import setFieldTimeToken from './setFieldTimeToken'
import setHTTPToken from './setHTTPToken'
import stripHTTPToken from './stripHTTPToken'
import broadcastChannel from './broadcastChannel'
import { datadogRum } from '@datadog/browser-rum'

import './App.css'
// import io from 'socket.io-client'
// import { oAuth, backbone } from '../../lib/http'
// import cookies from '../../lib/cookies'
// import moment from 'moment'
import Dialog from 'material-ui/Dialog'
import RaisedButton from 'material-ui/RaisedButton'
import CircularProgress from 'material-ui/CircularProgress'
import Snackbar from 'material-ui/Snackbar'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
// import { map } from 'lodash'
import MenuSidebar from '../../components/Sidebar/MenuSidebar.jsx'
import darkThemeStyle from '../../lib/themes/darkTheme'
import normalThemeStyle from '../../lib/themes/normalTheme'
// --------------------------------------------------------
// application wide state management is handled by Redux,
// redux has two components that make it work,
// mapStateToProps and mapDispatchToProps. The first maps
// values, the second maps functions.
// --------------------------------------------------------
import mapStateToProps from './mapStateToProps'
import mapDispatchToProps from './mapDispatchToProps'
// import fonts
import '../../lib/assets/fonts/fonts.css'
import '../../lib/assets/fonts/Roboto-300-cyrillic-ext1.woff2'
import '../../lib/assets/fonts/Roboto-300-cyrillic2.woff2'
import '../../lib/assets/fonts/Roboto-300-greek-ext3.woff2'
import '../../lib/assets/fonts/Roboto-300-greek4.woff2'
import '../../lib/assets/fonts/Roboto-300-vietnamese5.woff2'
import '../../lib/assets/fonts/Roboto-300-latin-ext6.woff2'
import '../../lib/assets/fonts/Roboto-300-latin7.woff2'
import '../../lib/assets/fonts/Roboto-400-cyrillic-ext8.woff2'
import '../../lib/assets/fonts/Roboto-400-cyrillic9.woff2'
import '../../lib/assets/fonts/Roboto-400-greek-ext10.woff2'
import '../../lib/assets/fonts/Roboto-400-greek11.woff2'
import '../../lib/assets/fonts/Roboto-400-vietnamese12.woff2'
import '../../lib/assets/fonts/Roboto-400-latin-ext13.woff2'
import '../../lib/assets/fonts/Roboto-400-latin14.woff2'
import '../../lib/assets/fonts/Roboto-500-cyrillic-ext15.woff2'
import '../../lib/assets/fonts/Roboto-500-cyrillic16.woff2'
import '../../lib/assets/fonts/Roboto-500-greek-ext17.woff2'
import '../../lib/assets/fonts/Roboto-500-greek18.woff2'
import '../../lib/assets/fonts/Roboto-500-vietnamese19.woff2'
import '../../lib/assets/fonts/Roboto-500-latin-ext20.woff2'
import '../../lib/assets/fonts/Roboto-500-latin21.woff2'
import '../../lib/assets/fonts/Titillium_Web-300-latin-ext1.woff2'
import '../../lib/assets/fonts/Titillium_Web-300-latin2.woff2'

// const file = '[src.App]'

// --------------------------------------------------------
// for some reason unshifting in a normal way was
// returning an integer instead of an array, so the
// prepend function was added, it works with this.
// --------------------------------------------------------
// const prepend = (value, array) => {
//   const newArray = array.slice()
//   newArray.unshift(value)
//   return newArray
// }
// --------------------------------------------------------
// _App will inherit state from the local storage state set
// in the Index.js file, if isAuthenticated is falsy, the
// router will force the user to go to the login page and
// prevent any further components to load.
// Any data that must be accessible in the header area must
// be sent to redux, because the header is loaded before the
// router.
// The router dictacts whether or not pages can be loaded.
// --------------------------------------------------------
class _App extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      initialLoader: true,
      loginExpiredModalOpen: false,
      errorSnackbar: false,
      errorMsg: null,
      selectedCompany: null,
      companies: [],
      departments: [],
      policies: [],
      user: null,
      token: null,
      titanToken: null,
      fieldTimeToken: null,
      sidebarOpen: false,
      darkTheme: window.localStorage.getItem('darkTheme') ? JSON.parse(window.localStorage.getItem('darkTheme')) : false,
      siteEntry: null,
      masterPage: null
    }
    this.setState = this.setState.bind(this)
    this.componentDidMount = componentDidMount.bind(this)
    this.componentWillUnmount = componentWillUnmount.bind(this)
    this.selectCompany = selectCompany.bind(this)
    this.setUser = setUser.bind(this)
    this.handleAuthToken = handleAuthToken.bind(this)
    this.attemptTokenRefresh = attemptTokenRefresh.bind(this)
    this.selectedCompany = selectedCompany.bind(this)
    this.setTitanToken = setTitanToken.bind(this)
    this.setFieldTimeToken = setFieldTimeToken.bind(this)
    this.setHTTPToken = setHTTPToken.bind(this)
    this.stripHTTPToken = stripHTTPToken.bind(this)
    this.broadcastChannel = broadcastChannel.bind(this)
  }

  render () {
    const {
      companies,
      departments,
      policies,
      selectedCompany,
      user,
      token,
      titanToken,
      fieldTimeToken,
      darkTheme,
      sidebarOpen,
      initialLoader,
      loginExpiredModalOpen,
      errorSnackbar,
      errorMsg,
      siteEntry
    } = this.state
    const {
      isAuthenticated,
      fullName,
      match
    } = this.props

    let theme = getMuiTheme({
      tabs: {
        backgroundColor: 'rgb(229, 229, 229)'
      }
    })

    if (darkTheme) {
      theme = getMuiTheme(darkThemeStyle)
    } else {
      theme = getMuiTheme(normalThemeStyle)
    }

    const ddUser = datadogRum.getUser()
    if (user && !ddUser.hasOwnProperty('id')) {
      datadogRum.setUser({
        id: user.id,
        name: user.full_name,
        email: user.email,
      })
    }

    return (
      <MuiThemeProvider muiTheme={theme}>
        {/* eslint-disable-next-line multiline-ternary */}
        {!initialLoader && !loginExpiredModalOpen ? (
          <BrowserRouter basename='/'>
            <div
              style={{
                backgroundColor: darkTheme ? '#141618' : '#fff'
              }}
            >
              <Snackbar
                open={errorSnackbar}
                message={errorMsg || ''}
                bodyStyle={{ backgroundColor: '#ff1744' }}
                autoHideDuration={5000}
                onRequestClose={() => {
                  this.setState({ errorSnackbar: false, errorMsg: null })
                }}
              />
              <Header
                loggedIn={isAuthenticated}
                userName={fullName}
                user={user}
                match={match}
                openMenuSidebar={() => this.setState({ sidebarOpen: !sidebarOpen })}
                companies={companies}
                departments={departments}
                policies={policies}
                selectedCompany={selectedCompany}
                selectCompany={(obj) => this.selectCompany(obj)}
                darkTheme={darkTheme}
              />
              <MenuSidebar
                user={user}
                isAdmin={user && user.admin}
                open={sidebarOpen}
                toggleSidebar={() => this.setState({ sidebarOpen: !sidebarOpen })}
              />
              <div
                id='main-content'
                style={{
                  backgroundColor: darkTheme ? '#141618' : '#f2f3f3'
                }}
              >
                <div id='content-inside' style={{ display: 'block' }}>
                  <Router
                    siteEntry={siteEntry}
                    company={selectedCompany}
                    selectCompany={(obj) => this.selectCompany(obj)}
                    selectedCompany={selectedCompany}
                    user={user}
                    setUser={(user) => this.setUser(user)}
                    isLoggedIn={(user && (user.active)) || false}
                    token={token}
                    titanToken={titanToken}
                    fieldTimeToken={fieldTimeToken}
                    darkTheme={darkTheme}
                    darkThemeEnabled={(bool) => {
                      window.localStorage.setItem('darkTheme', JSON.stringify(bool))
                      this.setState({
                        darkTheme: bool
                      }, () => {
                        setTimeout(() => {
                          window.location.reload(true)
                        }, 1000)
                      })
                    }}
                  />
                  <div style={{ clear: 'both' }} />
                </div>
              </div>
            </div>
          </BrowserRouter>
        ) : (
          // eslint-disable-next-line multiline-ternary
          loginExpiredModalOpen ? (
            <Dialog
              modal
              open={loginExpiredModalOpen}
              title='Session Expired'
              contentStyle={{ width: 400, maxWidth: 400 }}
              actionsContainerStyle={{ textAlign: 'center' }}
              actions={[
                <RaisedButton
                  key={'flush-cookies-and-refresh'}
                  label={'Flush Cookies and Refresh'}
                  style={{ width: '91%', marginBottom: 15 }}
                  onClick={() => {
                    this.stripHTTPToken()
                    window.location = '/'
                  }}
                />
                // ,
                // <a href='/login'>
                //   <RaisedButton
                //     label='OK'
                //     primary
                //     style={{ width: '91%', marginBottom: 15 }}
                //   />
                // </a>
              ]}
            >
              <div>Your session has expired, you will be redirected to the login page</div>
            </Dialog>
          ) : (
            <div style={{ height: '50vh', width: '100%', textAlign: 'center', bottom: 0 }}>
              <div style={{ marginTop: '35vh' }}>
                <div
                  style={{
                    lineHeight: 1.8,
                    fontSize: '2.5em',
                    fontWeight: 'bold',
                    color: '#F0F0F0',
                    fontFamily: 'Titillium Web, sans-serif',
                    textAlign: 'center',
                    marginBottom: 20
                  }}
                >
                  BACKBONE
                </div>
                <div style={{ display: 'inline-block' }}>
                  <CircularProgress size={80} thickness={5} color={'#F0F0F0'} />
                </div>
              </div>
            </div>
          )
        )}
      </MuiThemeProvider>
    )
  }
}
// --------------------------------------------------------
// the connect function will combine the redux functions
// and state into the react props making them accessible
// application wide. All redux state is available
// application wide. As a rule of thumb, always use local
// component state unless the variable or function must
// be available application wide. This will allow
// development to be quicker. You would write three times
// the amount of code trying to write everything as a redux
// state enabled function than if you default to local
// state.
// --------------------------------------------------------
const App = connect(mapStateToProps, mapDispatchToProps)(_App)
export default App
