async function componentDidMount () {
  const { user } = this.props

  if (user) {
    await this.socketConnection(user.id)
    this.setState({
      isLoading: true,
      notifications: [],
      page: 1
    }, async () => {
      await this.getNotifications(1)
    })
  }
}

export default componentDidMount
