import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import todos from './Todos.jsx'
import visibilityFilter from './VisibilityFilter.jsx'
import userAuth from './UserAuthReducer.jsx'
import admin from './AdminReducer.jsx'
import userInterface from './UserInterfaceReducer.jsx'
import arsenal from './ArsenalReducer.jsx'
import receivingLog from './ReceivingLogReducer.jsx'
import estScorecard from './EstimatingScorecardReducer.jsx'
import crewManagement from './CrewManagementReducer.jsx'

const rootReducer = combineReducers({
  todos,
  visibilityFilter,
  userAuth,
  userInterface,
  admin,
  arsenal,
  receivingLog,
  estScorecard,
  crewManagement,
  form: formReducer
})

export default rootReducer
