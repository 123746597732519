/* eslint-disable no-async-promise-executor */

import cookies from '../../lib/cookies'
import { OAUTH } from '../../lib/http'
import { AUTHTOKEN } from '../../lib/constants'
import logger from '../../lib/log'

const log = logger('containers:App:attemptTokenRefresh')

function attemptTokenRefresh (token) {
  return new Promise(async (resolve, reject) => {
    try {
      log.debug('attempt token refresh')

      let responseToken = null
      const details = await OAUTH.post('/refresh', { token })
      responseToken = details.data.token
      this.setHTTPToken(responseToken)
      log.debug(`new token is - ${responseToken}`)
      log.debug(`cookie token is - ${cookies.get(AUTHTOKEN)}`)
      resolve(responseToken)
    } catch (err) {
      log.fatal('failed to refresh token, remove cookies')
      cookies.delete(AUTHTOKEN)
      reject(err)
    }
  })
}

export default attemptTokenRefresh
