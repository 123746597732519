function deleteAll () {
  const c = document.cookie.split(';')
  for (let i = 0; i < c.length; i++) {
    const e = c[i].indexOf('=')
    const n = e > -1 ? c[i].substr(0, e) : c[i]
    document.cookie = n + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
  }
}

export default deleteAll
