const crewManagement = (
  state = {
    selectedView: null,
    resetMasterList: false,
    searchName: null,
    makeQuery: false,
    myself: null
  }, action) => {
  switch (action.type) {
  case 'CHANGE_VIEW':
    return Object.assign({}, state, {
      selectedView: action.selectedView
    })
  case 'UPDATE_SEARCH_NAME':
    return Object.assign({}, state, {
      searchName: action.searchName,
      makeQuery: action.makeQuery
    })
  case 'SEARCH_FOR_NAME':
    return Object.assign({}, state, {
      selectedView: action.selectedView,
      makeQuery: action.makeQuery
    })
  case 'FRESH_MASTER_LIST':
    return Object.assign({}, state, {
      resetMasterList: action.resetMasterList
    })
  case 'ARSENAL_USER_QUERY':
    return Object.assign({}, state, {
      myself: action.myself
    })
  default:
    return state
  }
}

export default crewManagement
