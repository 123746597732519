function setFieldTimeToken (user) {
  let fieldTimeToken = null
  user._embedded.connected_accounts.forEach(account => {
    if (account.account === 'fieldtime') {
      window.localStorage.setItem('fieldTimeToken', account.token)
      fieldTimeToken = account.token
    }
  })
  return fieldTimeToken
}

export default setFieldTimeToken
