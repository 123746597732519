function selectedCompany (user) {
  let selectedCompany
  user._embedded.companies.map(c => {
    if (window.localStorage.getItem('app.companyId') && c.ts_id === parseInt(window.localStorage.getItem('app.companyId'))) {
      selectedCompany = c
    }
  })
  return selectedCompany
}

export default selectedCompany
