import { io } from 'socket.io-client'

function websocket (token) {
  return io(process.env.NOTIFICATION_API, {
    closeOnBeforeunload: false,
    auth: { token }
  })
}

export default websocket
