// import axios from 'axios'
import { backbone } from '../lib/http.js'

export const changeAppTitle = title => {
  return dispatch => {
    dispatch({
      type: 'UPDATE_APP_TITLE',
      title
    })
  }
}

export const clearErrorMessage = () => {
  return dispatch => {
    dispatch({
      type: 'CLEAR_ERROR_MESSAGE'
    })
  }
}

export const receivedNotifications = (values) => {
  let unreadNotifications = 0
  for (let i = 0; i < values.notifications.length; i++) {
    const x = values.notifications[i]
    if (x.read === false) {
      unreadNotifications++
    }
  }
  return {
    type: 'REQUESTED_NOTIFICATIONS_SUCCESS',
    fetchingNotifications: false,
    notifications: values.notifications,
    notificationCount: values.count,
    unreadNotifications
  }
}

export const errorGettingNotifications = (message) => {
  return {
    type: 'REQUESTED_NOTIFICATIONS_FAILED',
    fetchingNotifications: false,
    errorMessage: message.error
  }
}

export const openMenuSidebar = () => {
  return {
    type: 'OPEN_MENU_SIDEBAR',
    menuSidebarOpen: true
  }
}

export const closeMenuSidebar = () => {
  return {
    type: 'CLOSE_MENU_SIDEBAR',
    menuSidebarOpen: false
  }
}

export const openNotificationSidebar = () => {
  return {
    type: 'OPEN_NOTIFICATION_SIDEBAR',
    notificationSidebarOpen: true
  }
}

export const closeNotificationSidebar = () => {
  return {
    type: 'CLOSE_NOTIFICATION_SIDEBAR',
    notificationSidebarOpen: false
  }
}

export const getNotifications = (notifications) => {
  return dispatch => {
    dispatch({
      type: 'REQUESTED_NOTIFICATIONS',
      fetchingNotifications: true
    })
    backbone.get('/notifications')
      .then(result => {
        const values = {
          notifications: result.data.data.notifications,
          notificationCount: result.data.records
        }
        // first check if array lengths are the same
        // check for new notification
        for (let i = 0; i < values.notifications.length; i++) {
          const x = values.notifications[i]
          let m = false
          for (let a = 0; a < notifications.length; a++) {
            const b = notifications[a]
            if (x.id === b.id) {
              m = true
            }
          }
          // if notification is new, dispatch a snackbar
          if (m === false && x.read === false) {
            dispatch(dispatchSnackbar(x))
          }
        }
        dispatch(receivedNotifications(values))
      })
      .catch(_error => {
        //
      })
  }
}

export const markNotificationAsRead = (id, notifications) => {
  return dispatch => {
    dispatch(updateNotificationOnStoreAsRead(id, notifications))
    backbone.patch(`/notifications/${id}?read=true`)
      .then(result => {
        // dispatch(getNotifications(notifications))
      })
      .catch(error => {
        const message = {
          error: Object.assign({}, error).response.data.error,
          jsError: error
        }
        dispatch(errorGettingNotifications(message))
      })
  }
}

export const updateNotificationOnStoreAsRead = (id, notifications) => {
  const updatedNotifications = []
  for (let i = 0; i < notifications.length; i++) {
    const x = notifications[i]
    if (x.id === id) {
      x.read = true
    }
    updatedNotifications.push(x)
  }
  return {
    type: 'MARK_NOTIFICATION_AS_READ',
    notifications: updatedNotifications
  }
}

export const deleteNotification = (id, notifications) => {
  return dispatch => {
    dispatch(deleteNotificationOnStore(id, notifications))
    backbone.delete(`/notifications/${id}`)
      .then(result => {
        // dispatch(getNotifications(notifications))
      })
      .catch(error => {
        const message = {
          error: Object.assign({}, error).response.data.error,
          jsError: error
        }
        dispatch(errorGettingNotifications(message))
      })
  }
}

export const deleteNotificationOnStore = (id, notifications) => {
  const updatedNotifications = []
  for (let i = 0; i < notifications.length; i++) {
    const x = notifications[i]
    if (x.id !== id) {
      updatedNotifications.push(x)
    }
  }
  return {
    type: 'DELETE_NOTIFICATION',
    notifications: updatedNotifications
  }
}

// example:
// dispatchSnackbar({title: 'Success', body: 'Deleted Application from Department'})
export const dispatchSnackbar = (messageObject) => {
  return dispatch => {
    dispatch({
      type: 'OPEN_SNACKBAR',
      snackbarOpen: true,
      snackbarMessage: messageObject
    })
    // backboneUtils.wait(4000) // wait 4 seconds
    dispatch({
      type: 'CLOSE_SNACKBAR',
      snackbarOpen: false,
      snackbarMessage: ''
    })
  }
}

// export const openSnackbar = (message) => {
//   return {
//     type: 'OPEN_SNACKBAR',
//     snackbarOpen: true,
//     snackbarMessage: message
//   }
// }
//
// export const closeSnackbar = () => {
//   return {
//     type: 'CLOSE_SNACKBAR',
//     snackbarOpen: false,
//     snackbarMessage: ''
//   }
// }

export const addNotification = obj => {
  return dispatch => {
    dispatch({
      type: 'ADD_NOTIFICATION',
      unreadNotifications: obj.unreadNotifications,
      notificationCount: obj.notificationCount,
      notifications: obj.notifications
    })
  }
}

export const unreadCount = (count) => {
  return dispatch => {
    dispatch({
      type: 'UNREAD_NOTIFICATION_COUNT',
      unreadNotifications: count
    })
  }
}
