/* eslint-disable react/prop-types */
import React from 'react'
import Drawer from 'material-ui/Drawer'
import MenuItem from 'material-ui/MenuItem'
import { Link } from 'react-router-dom'
// import AppBar from 'material-ui/AppBar'
// import NavigationClose from 'material-ui/svg-icons/navigation/close'
import NavigationIcon from 'material-ui/svg-icons/action/dashboard'
import { map } from 'lodash'
import { List, ListItem } from 'material-ui/List'
import Divider from 'material-ui/Divider'
import InfoOutline from 'material-ui/svg-icons/action/info-outline'
import Settings from 'material-ui/svg-icons/action/settings'
import LogoutIcon from 'material-ui/svg-icons/action/power-settings-new'
import dashboard from '../../lib/dashboard'
import appIcons from '../../lib/appIcons'
import Menu from 'material-ui/Menu'

import './MenuSidebar.css'

const Sidebar = props => {
  const {
    open,
    toggleSidebar,
    user,
    isAdmin
  } = props

  return (
    <div style={{ float: 'right' }}>
      <Drawer
        docked={false}
        width={300}
        open={open}
        onRequestChange={() => toggleSidebar()}
        style={{ textAlign: 'left' }}
      >
        <List
          style={{ padding: 0, width: '100%' }}
        >
          <div style={{
            paddingTop: 5,
            width: '100%',
            lineHeight: 2,
            borderBottom: '1px solid rgb(224, 224, 224)'
          }}>
            <div
              style={{
                width: '100%',
                paddingLeft: 15,
                fontSize: '80%',
                float: 'left',
                fontWeight: 'bold',
                color: 'rgba(0, 0, 0, 0.54)'
              }}
            >
              {'Quick Navigation'}
            </div>
            <div style={{ clear: 'both' }} />
          </div>
          <Divider style={{ padding: 0 }} />
        </List>
        <List>
          <ListItem
            leftIcon={<NavigationIcon width='24' height='24' />}
            primaryText={'Home'}
            onClick={() => toggleSidebar()}
            containerElement={<Link to={'/'} style={{ textDecoration: 'none' }} />}
          />
          {user && map(dashboard(user._embedded.policies, user.admin), (app, i) => {
            if (app.title === 'Bid Board') return
            if (app.title === 'Field Pipeline') return
            if (app.arr === 'secured') {
              if (app.type === 'link') {
                return (
                  <ListItem
                    key={i}
                    leftIcon={<img src={app.img} alt='' width='24' height='24' />}
                    primaryText={app.title}
                    onClick={() => toggleSidebar()}
                    containerElement={<Link to={app.url} style={{ textDecoration: 'none' }} />}
                  />
                )
              } else {
                return (
                  <ListItem
                    key={i}
                    leftIcon={<img src={app.img} alt='' width='24' height='24' />}
                    primaryText={app.title}
                    onClick={() => toggleSidebar()}
                    containerElement={<a target='_blank' rel='noreferrer' href={app.url} style={{ textDecoration: 'none' }} />}
                  />
                )
              }
            }
          })}
          {isAdmin &&
            <ListItem
              primaryText='Sync Status'
              onClick={() => toggleSidebar()}
              leftIcon={<img src={appIcons('backbone-auth')} alt='' width='24' height='24' />}
              containerElement={<Link to={'/sync-status'} />}
            />
          }
          <Divider />
        </List>
        <Menu>
          {user &&
            <MenuItem
              primaryText={user && user.full_name}
              leftIcon={<Settings />}
              onClick={() => toggleSidebar()}
              containerElement={<Link to={'/account'} />}
            />
          }
          {isAdmin &&
            <MenuItem
              primaryText='Admin Panel'
              leftIcon={<InfoOutline />}
              onClick={() => toggleSidebar()}
              containerElement={<Link to='/admin-panel' />}
            />
          }
          {!user &&
            <MenuItem
              primaryText='Login'
              leftIcon={<LogoutIcon />}
              onClick={() => toggleSidebar()}
              containerElement={<Link to='/login' />}
            />
          }
        </Menu>
      </Drawer>
    </div>
  )
}

export default Sidebar
