import getAll from './getAll'
import get from './get'
import set from './set'
import deleteCookie from './delete'
import deleteAll from './deleteAll'

const cookies = {
  getAll,
  get,
  set,
  delete: deleteCookie,
  deleteAll
}

export default cookies
