/* eslint-disable no-case-declarations */
import moment from 'moment'

const som = moment().startOf('month')
export const defaultSnapshot = {
  start_date: som,
  end_date: moment(som.format('YYYY-MM-DD')).endOf('month'),
  prospecting: { count: 0, value: 0.0 },
  development: { count: 0, value: 0.0 },
  submitted: { count: 0, value: 0.0 },
  committed: { count: 0, value: 0.0 },
  review: { count: 0, value: 0.0 },
  awarded: { count: 0, value: 0.0, count_percent: 0.0, value_percent: 0.0 },
  lost: { count: 0, value: 0.0, count_percent: 0.0, value_percent: 0.0 },
  teams: {
    prospecting: { count: 0, value: 0.0 },
    development: { ount: 0, value: 0.0 },
    submitted: { count: 0, value: 0.0 },
    committed: { count: 0, value: 0.0 },
    review: { count: 0, value: 0.0 },
    awarded: { count: 0, value: 0.0, count_percent: 0.0, value_percent: 0.0 },
    lost: { count: 0, value: 0.0, count_percent: 0.0, value_percent: 0.0 }
  },
  sage_summary: {
    major_construction: { cost: 0.0, hours: 0.0 },
    tenant_improvement: { cost: 0.0, hours: 0.0 },
    san_diego: { cost: 0.0, hours: 0.0 },
    retrofit: { cost: 0.0, hours: 0.0 },
    plumbing: { cost: 0.0, hours: 0.0 },
    north: { cost: 0.0, hours: 0.0 },
    service: { cost: 0.0, hours: 0.0 },
    accs: { cost: 0.0, hours: 0.0 },
    oed: { cost: 0.0, hours: 0.0 },
    airflow: { cost: 0.0, hours: 0.0 },
    ecoduct: { cost: 0.0, hours: 0.0 },
    insultech: { cost: 0.0, hours: 0.0 },
    pre_construction: { cost: 0.0, hours: 0.0 },
    overhead: { cost: 0.0, hours: 0.0 },
    other: { cost: 0.0, hours: 0.0 },
    total: { cost: 0.0, hours: 0.0 }
  },
  _embedded: {}
}
const defaultSageSummary12 = {
  sage_summary: {
    major_construction: { cost: 0.0, hours: 0.0 },
    tenant_improvement: { cost: 0.0, hours: 0.0 },
    san_diego: { cost: 0.0, hours: 0.0 },
    retrofit: { cost: 0.0, hours: 0.0 },
    plumbing: { cost: 0.0, hours: 0.0 },
    north: { cost: 0.0, hours: 0.0 },
    service: { cost: 0.0, hours: 0.0 },
    accs: { cost: 0.0, hours: 0.0 },
    oed: { cost: 0.0, hours: 0.0 },
    airflow: { cost: 0.0, hours: 0.0 },
    ecoduct: { cost: 0.0, hours: 0.0 },
    insultech: { cost: 0.0, hours: 0.0 },
    pre_construction: { cost: 0.0, hours: 0.0 },
    overhead: { cost: 0.0, hours: 0.0 },
    other: { cost: 0.0, hours: 0.0 },
    total: { cost: 0.0, hours: 0.0 }
  }
}
export const defaultYearSummary = {
  hvacAwarded: { total_count: { value: 0 }, total_sum: { value: 0 } },
  hvacLost: { total_count: { value: 0 }, total_sum: { value: 0 } },
  hvacTeamAwarded: { total_count: { value: 0 }, total_sum: { value: 0 } },
  hvacTeamLost: { total_count: { value: 0 }, total_sum: { value: 0 } },
  plbgAwarded: { total_count: { value: 0 }, total_sum: { value: 0 } },
  plbgLost: { total_count: { value: 0 }, total_sum: { value: 0 } },
  plbgTeamAwarded: { total_count: { value: 0 }, total_sum: { value: 0 } },
  plbgTeamLost: { total_count: { value: 0 }, total_sum: { value: 0 } },
  teamTotalAwarded: { total_count: { value: 0 } },
  teamTotalLost: { total_count: { value: 0 } },
  totalAwarded: { total_count: { value: 0 } },
  totalLost: { total_count: { value: 0 } },
  teamProspecting: { total_count: { value: 0 }, total_sum: { value: 0 } },
  teamDevelopment: { total_count: { value: 0 }, total_sum: { value: 0 } },
  teamSubmitted: { total_count: { value: 0 }, total_sum: { value: 0 } },
  teamCommitted: { total_count: { value: 0 }, total_sum: { value: 0 } },
  teamReview: { total_count: { value: 0 }, total_sum: { value: 0 } },
  hvacJobsWon: { total_count: { value: 0 }, total_sum: { value: 0 } },
  hvacJobsLost: { total_count: { value: 0 }, total_sum: { value: 0 } },
  plumbingJobsWon: { total_count: { value: 0 }, total_sum: { value: 0 } },
  plumbingJobsLost: { total_count: { value: 0 }, total_sum: { value: 0 } },
  comboHvacJobsWon: { total_count: { value: 0 }, total_sum: { value: 0 } },
  comboHvacJobsLost: { total_count: { value: 0 }, total_sum: { value: 0 } },
  comboPlumbingJobsWon: { total_count: { value: 0 }, total_sum: { value: 0 } },
  comboPlumbingJobsLost: { total_count: { value: 0 }, total_sum: { value: 0 } },
  comboJobsLostCount: { total_count: { value: 0 } },
  comboJobsWonCount: { total_count: { value: 0 } },
  // new team values
  teamHvacJobsWon: { total_count: { value: 0 }, total_sum: { value: 0 } },
  teamHvacJobsLost: { total_count: { value: 0 }, total_sum: { value: 0 } },
  teamPlumbingJobsWon: { total_count: { value: 0 }, total_sum: { value: 0 } },
  teamPlumbingJobsLost: { total_count: { value: 0 }, total_sum: { value: 0 } },
  teamComboHvacJobsWon: { total_count: { value: 0 }, total_sum: { value: 0 } },
  teamComboHvacJobsLost: { total_count: { value: 0 }, total_sum: { value: 0 } },
  teamComboPlumbingJobsWon: { total_count: { value: 0 }, total_sum: { value: 0 } },
  teamComboPlumbingJobsLost: { total_count: { value: 0 }, total_sum: { value: 0 } },
  teamComboJobsLostCount: { total_count: { value: 0 } },
  teamComboJobsWonCount: { total_count: { value: 0 } }

}

const estScorecard = (
  state = {
    errorMessage: null,
    isFetching: false,
    callingAction: null,
    errorCaller: null,
    userInfo: {},
    estimators: [],
    projectTypes: [],
    subTypes: [],
    reportRange: [],
    queryParams: {
      cmd: 'development',
      month: {
        beginning: som,
        end: moment(som.format('YYYY-MM-DD')).endOf('month')
      },
      estimator: '',
      projectSummary: {
        isLoading: false,
        view: 'running-12-months', // month or all are the two other options
        projectType: null,
        subType: null,
        teamsOnly: false,
        showDollars: false,
        modified: false,
        page: 1,
        totalPages: 1,
        tabs: {
          development: { page: 1, projects: [], totalPages: 1 },
          submitted: { page: 1, projects: [], totalPages: 1 },
          committed: { page: 1, projects: [], totalPages: 1 },
          review: { page: 1, projects: [], totalPages: 1 }
        }
      }
    },
    bidTotal: { count: 0, sum: 0 },
    prospecting: { count: 0, sum: 0 },
    development: { count: 0, sum: 0 },
    submitted: { count: 0, sum: 0 },
    committed: { count: 0, sum: 0 },
    review: { count: 0, sum: 0 },
    awarded: { count: 0, sum: 0, percent: 0.0 },
    lost: { count: 0, sum: 0, percent: 0.0 },
    teamAwarded: { count: 0, sum: 0, percent: 0.0 },
    teamLost: { count: 0, sum: 0, percent: 0.0 },
    snapshot: defaultSnapshot,
    bidsPerWeekData: [],
    // Performance Summary (year)
    yearAwarded: 0,
    yearLost: 0,
    yearTeamAwarded: 0,
    yearTeamLost: 0,
    yearSummary: defaultYearSummary,
    sageSummaryTwelve: defaultSageSummary12
    // Performance Summary (month)
  }, action) => {
  switch (action.type) {
  case 'EST_SCORECARD_ERROR':
    const error = Object.assign({}, action.errorMessage)
    return Object.assign({}, state, {
      errorMessage: error ? error.response.data.error : null,
      errorCaller: action.errorCall,
      isFetching: false
    })
  case 'FETCH_EST_SCORECARD':
    return Object.assign({}, state, {
      isFetching: true,
      callingAction: action.callingAction
    })
  case 'ESTIMATOR_LIST':
    return Object.assign({}, state, {
      estimators: action.estimators,
      isFetching: false
    })
  case 'EST_SCORECARD_PROJECT_TYPES':
    return Object.assign({}, state, {
      isFetching: false,
      projectTypes: action.projectTypes
    })
  case 'EST_SCORECARD_SUB_TYPES':
    return Object.assign({}, state, {
      subTypes: action.subTypes
    })
  case 'EST_SCORECARD_REPORT_RANGE':
    return Object.assign({}, state, {
      reportRange: action.reportRange
    })
  case 'EST_SCORECARD_NEW_PARAMS':
    return Object.assign({}, state, {
      queryParams: action.queryParams,
      selectedDate: action.selectedDate
    })
  case 'EST_SCORECARD_RUNNING_SUMMARY':
    return Object.assign({}, state, {
      bidTotal: action.bidTotal
    })
  case 'EST_SCORECARD_DYNAMIC_SUMMARY':
    return Object.assign({}, state, {
      [action.summaryType]: action.value
    })
  case 'EST_SCORECARD_SNAPSHOT':
    return Object.assign({}, state, {
      snapshot: action.snapshot
    })
  case 'EST_BIDS_PER_WEEK_SNAPSHOT':
    return Object.assign({}, state, {
      bidsPerWeekData: action.bidsPerWeekData
    })
  case 'EST_SCORECARD_PERFORMANCE_YEAR_SUM':
    return Object.assign({}, state, {
      yearSummary: {
        ...state.yearSummary,
        hvacAwarded: action.hvac_awarded,
        plbgAwarded: action.plbg_awarded,
        hvacLost: action.hvac_lost,
        plbgLost: action.plbg_lost,
        hvacTeamAwarded: action.hvac_team_awarded,
        plbgTeamAwarded: action.plbg_team_awarded,
        hvacTeamLost: action.hvac_team_lost,
        plbgTeamLost: action.plbg_team_lost,
        totalAwarded: action.total_awarded,
        totalLost: action.total_lost,
        teamTotalAwarded: action.team_total_awarded,
        teamTotalLost: action.team_total_lost,
        teamProspecting: action.team_prospecting,
        teamDevelopment: action.team_development,
        teamSubmitted: action.team_submitted,
        teamCommitted: action.team_committed,
        teamReview: action.team_review,
        // new values
        hvacJobsWon: action.hvac_jobs_won,
        hvacJobsLost: action.hvac_jobs_lost,
        plumbingJobsWon: action.plumbing_jobs_won,
        plumbingJobsLost: action.plumbing_jobs_lost,
        comboHvacJobsWon: action.combo_hvac_jobs_won,
        comboHvacJobsLost: action.combo_hvac_jobs_lost,
        comboPlumbingJobsWon: action.combo_plumbing_jobs_won,
        comboPlumbingJobsLost: action.combo_plumbing_jobs_lost,
        comboJobsLostCount: action.combo_jobs_lost_count,
        comboJobsWonCount: action.combo_jobs_won_count,
        // new team values
        teamHvacJobsWon: action.team_hvac_jobs_won,
        teamHvacJobsLost: action.team_hvac_jobs_lost,
        teamPlumbingJobsWon: action.team_plumbing_jobs_won,
        teamPlumbingJobsLost: action.team_plumbing_jobs_lost,
        teamComboHvacJobsWon: action.team_combo_hvac_jobs_won,
        teamComboHvacJobsLost: action.team_combo_hvac_jobs_lost,
        teamComboPlumbingJobsWon: action.team_combo_plumbing_jobs_won,
        teamComboPlumbingJobsLost: action.team_combo_plumbing_jobs_lost,
        teamComboJobsLostCount: action.team_combo_jobs_lost_count,
        teamComboJobsWonCount: action.team_combo_jobs_won_count
      }
    })
  case 'EST_SCORECARD_SAGE_SUMMARY_12':
    return Object.assign({}, state, {
      sageSummaryTwelve: action.sageSummary12
    })
  case 'EST_SCORECARD_PROJECT_SUMMARY':
    return Object.assign({}, state, {
      queryParams: {
        ...state.queryParams,
        cmd: action.tab,
        projectSummary: {
          ...state.queryParams.projectSummary,
          isLoading: false,
          tabs: {
            ...state.queryParams.projectSummary.tabs,
            [action.tab]: {
              page: action.body.page,
              totalPages: action.body.totalPages,
              projects: action.body.projects
            }
          }
        }
      }
    })
  case 'EST_SCORECARD_PROJECT_SUMMARY_FETCHING':
    return Object.assign({}, state, {
      queryParams: {
        ...state.queryParams,
        projectSummary: {
          ...state.queryParams.projectSummary,
          isLoading: action.isLoading
        }
      }
    })
  default:
    return state
  }
}

export default estScorecard
