import axios from 'axios'
// import rateLimit from 'axios-rate-limit'
// import awsAuth from './aws-auth.js'
// import cookies from './cookies'
// import { AUTHTOKEN } from './constants'

export const OAUTH = axios.create({
  baseURL: process.env.OAUTH_API,
  headers: {
    'Content-Type': 'application/json'
  }
})
// ---------------------------------------
// generate an axios wrapper for backbone
// ---------------------------------------
export const backbone = axios.create({
  baseURL: process.env.BACKBONE_AUTH_API,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const BB = backbone

export const arsenal = axios.create({
  baseURL: process.env.CREW_MANAGEMENT_API,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const CM = arsenal

export const receivingLog = axios.create({
  baseURL: process.env.RECEIVING_LOG_API,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const snapshot = axios.create({
  baseURL: process.env.SNAPSHOT_API,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const SNAP = snapshot

export const titan = axios.create({
  baseURL: process.env.TITAN_API,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const T2 = titan

export const CAMP = axios.create({
  baseURL: process.env.CAMP_API,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const T2_ROOT = axios.create({
  baseURL: process.env.TITAN_ROOT_API,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const TitanV1 = axios.create({
  baseURL: `${process.env.TITAN_ROOT_API}/v1`,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const deliveries = axios.create({
  baseURL: process.env.TITAN_API,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const hilti = axios.create({
  baseURL: `${process.env.HILTI_API}/api/`,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const confluence = axios.create({
  baseURL: process.env.CONFLUENCE_API || 'https://lmd.controlac.com/v1/confluence',
  headers: {
    'Content-Type': 'application/json'
  }
})

// export const jobData = rateLimit(
//   axios.create({
//     baseURL: process.env.JOBDATA_API,
//     headers: {
//       'Authorization': cookies.get(AUTHTOKEN),
//       'Content-Type': 'application/json'
//     }
//   }),
//   {
//     maxRequests: 1,
//     perMilliseconds: 900
//   }
// )

export const jobData = axios.create({
  baseURL: process.env.JOBDATA_API,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const JD = jobData

export const employeeLists = axios.create({
  baseURL: process.env.EMPLOYEE_LIST_API,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const EL = employeeLists

export const EF = axios.create({
  baseURL: process.env.EQUALITY_FUND_API,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const RL = axios.create({
  baseURL: process.env.RECEIVING_LOG_API,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const EXL = axios.create({
  baseURL: process.env.EXCEL_INTEGRATION_API,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const FT = axios.create({
  baseURL: process.env.FIELDTIME_API,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const FM = axios.create({
  baseURL: process.env.FILE_MANAGER_API,
  headers: {
    'Content-Type': 'application/json'
  }
})

const elasticSearch = axios.create({
  baseURL: process.env.ELASTICSEARCH_API,
  headers: {
    'Content-Type': 'application/json'
  }
})
// if (process.env.NODE_ENV === 'production') {
//   elasticSearch.interceptors.request.use((config) => {
//     config.headers['Content-Type'] = 'application/json'
//     config.headers.Host = config.baseURL.replace(/http(s)?:\/{2}/, '')
//     config.headers['X-Amz-Date'] = new Date().toISOString().replace(/[:-]|\.\d{3}/g, '')
//     config.headers.Authorization = awsAuth.authorize(config)
//     const signedHeaders = config.headers
//     // ----------------------------------
//     // Strip out anything that will make
//     // elasticsearch unhappy
//     // ----------------------------------
//     delete signedHeaders.Host
//     delete signedHeaders.Token
//     config.headers = signedHeaders
//     return config
//   })
// }
export const ES = elasticSearch
export {
  elasticSearch
}

const http = {
  OAUTH,
  BB,
  CM,
  T2,
  CAMP,
  JD,
  EL,
  EF,
  RL,
  EXL,
  FT,
  FM,
  ES,
  arsenal,
  receivingLog,
  snapshot,
  SNAP,
  titan,
  deliveries,
  TitanV1,
  jobData,
  employeeLists,
  elasticSearch,
  T2_ROOT,
  hilti,
  confluence
}

export default http
