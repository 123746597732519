import http from '../../lib/http'
import cookies from '../../lib/cookies'
import { AUTHTOKEN, DOMAIN } from '../../lib/constants'
import { cloneDeep } from 'lodash'
import logger from '../../lib/log'

const log = logger('containers:App:stripHTTPToken')

function stripHTTPToken () {
  log.debug('iterating over axios http methods and stripping Authorization token')
  Object.entries(http).map(name => {
    const KEY = name[0]
    const skippers = ['ES']
    if (!skippers.includes(KEY)) {
      const headers = cloneDeep(http[KEY].defaults.headers)
      delete headers.common.Authorization
      http[KEY].defaults.headers = headers
    }
  })
  cookies.delete(AUTHTOKEN, process.env.NODE_ENV === 'production' ? DOMAIN : null)
  cookies.deleteAll()
}

export default stripHTTPToken
