import appIcons from '../appIcons'
import moment from 'moment'

const dashboard = (policies, admin) => {
  let modules = []
  const secured = [
    {
      arr: 'secured',
      title: 'Crew Data',
      secondary: 'Crew Management',
      short: 'Manage Crew Lists',
      long: '',
      type: 'link',
      url: '/crew-management',
      img: appIcons('crew-management')
    },
    {
      arr: 'secured',
      title: 'Job Data',
      secondary: 'Job Information',
      short: 'Details about Jobs',
      long: '',
      type: 'link',
      url: '/job-data',
      img: appIcons('job-data')
    },
    {
      arr: 'secured',
      title: 'Receiving Data',
      secondary: 'Receiving Log',
      short: 'Shop Log',
      long: '',
      type: 'link',
      url: '/receiving-log',
      img: appIcons('receiving-log')
    },
    {
      arr: 'secured',
      title: 'Estimating Data',
      secondary: 'Snapshot',
      short: 'Est Dept Analytics',
      long: '',
      type: 'link',
      url: '/dashboards/estimating',
      img: appIcons('snapshot')
    },
    {
      arr: 'secured',
      title: 'Field Time',
      secondary: '',
      short: 'Settings',
      long: '',
      type: 'link',
      url: '/fieldtime',
      img: appIcons('fieldtime-settings')
    },
    // {
    //   arr: 'secured',
    //   title: 'Deliveries',
    //   secondary: '',
    //   short: 'Jobsite Deliveries',
    //   long: '',
    //   type: 'link',
    //   url: '/deliveries',
    //   img: appIcons('deliveries')
    // },
    // {
    //   arr: 'secured',
    //   title: 'Bid Board',
    //   secondary: '',
    //   short: 'Titan 2.0 Bid Board',
    //   long: '',
    //   type: 'a',
    //   url: `${process.env.BID_BOARD_UI}/business-development?referrer=${window.btoa('backbone:' + window.localStorage.authtoken)}`,
    //   img: appIcons('bid-board')
    // },
    {
      arr: 'secured',
      title: 'My Data',
      secondary: '',
      short: 'Reports & Scorecards',
      long: '',
      type: 'link',
      url: '/my-data',
      img: appIcons('my-data')
    },
    {
      arr: 'secured',
      title: 'Field Data',
      secondary: 'Field Pipeline',
      short: 'Field Reports',
      long: '',
      type: 'link',
      url: '/field-pipeline/authentication-router',
      img: appIcons('field-pipeline')
    },
    {
      arr: 'secured',
      title: 'Resources',
      secondary: 'Forecast',
      short: 'Forecasting',
      long: '',
      type: 'link',
      url: '/resource-tracking',
      img: appIcons('forecast')
    },
    {
      arr: 'regular',
      title: 'Titan 3.0 alpha',
      secondary: 'Titan',
      short: 'Progressive Release',
      long: '',
      type: 'link',
      url: '/titan',
      img: appIcons('titan-3')
    },
    {
      arr: 'secured',
      title: 'Power BI',
      secondary: 'Business Intelligence',
      short: 'Business Intelligence',
      long: '',
      type: 'link',
      url: '/bi/departments',
      img: appIcons('business-intelligence')
    }
    // {
    //   arr: 'secured',
    //   title: 'Pricing Table',
    //   secondary: '',
    //   short: 'Rates and Measures',
    //   long: '',
    //   type: 'link',
    //   url: '/pricing-table',
    //   img: appIcons('pricing-table')
    // }
  ]
  const adminAccess = [
    // {
    //   arr: 'admin',
    //   title: 'Kibana',
    //   secondary: '',
    //   short: 'Application Metrics',
    //   long: '',
    //   type: 'a',
    //   url: 'http://kibana.controlac.com/app/kibana#/dashboards',
    //   img: appIcons('kibana')
    // },
    {
      arr: 'secured',
      title: 'Goal Data',
      secondary: 'Employee Lists',
      short: 'Management Data',
      long: '',
      type: 'link',
      url: '/employee-lists',
      img: appIcons('employee-lists')
    },
    {
      arr: 'secured',
      title: 'Financial Data',
      secondary: 'Employee Lists',
      short: 'Accounting Data',
      long: '',
      type: 'link',
      url: `/financials?fiscal_start=${moment(moment().format('YYYY') + '-01-01').format('YYYY-MM-DD')}`,
      img: appIcons('financials')
    },
    {
      arr: 'admin',
      title: 'Device Data',
      secondary: '',
      short: 'Block/Unblock Devices',
      type: 'link',
      url: '/admin/devices',
      img: appIcons('devices')
    },
    {
      arr: 'admin',
      title: 'Sync Status',
      secondary: '',
      short: 'Running Syncs',
      long: '',
      type: 'link',
      url: '/sync-status',
      img: appIcons('backbone-auth')
    },
    {
      arr: 'admin',
      title: 'Admin Panel',
      secondary: '',
      short: 'Manage User Access',
      long: '',
      type: 'link',
      url: '/admin-panel',
      img: appIcons('admin-settings')
    },
    {
      arr: 'admin',
      title: 'System Health',
      secondary: '',
      short: 'Check for Broken Services',
      long: '',
      type: 'link',
      url: '/health-reports',
      img: appIcons('health')
    }
  ]
  const regular = [
    // {
    //   arr: 'regular',
    //   title: 'Tech Support',
    //   secondary: '',
    //   short: 'Support Workload',
    //   long: '',
    //   type: 'a',
    //   url: 'http://ticket.controlac.com',
    //   img: appIcons('tech-support')
    // },
    // {
    //   arr: 'regular',
    //   title: 'Development',
    //   secondary: '',
    //   short: 'Development Workload',
    //   long: '',
    //   type: 'a',
    //   url: 'https://controlac.atlassian.net/issues/?filter=-5',
    //   img: appIcons('jira')
    // },
    // {
    //   arr: 'regular',
    //   title: 'Settings',
    //   secondary: '',
    //   short: 'Backbone Settings',
    //   long: '',
    //   type: 'link',
    //   url: '/account',
    //   img: appIcons('user-settings')
    // }
    // {
    //   arr: 'regular',
    //   title: 'Logout',
    //   secondary: '',
    //   short: 'Exit Backbone Tools',
    //   long: '',
    //   type: 'link',
    //   url: '/logout',
    //   img: appIcons('logout')›
    // }
    {
      arr: 'regular',
      title: 'Req Center',
      secondary: '',
      short: 'View/Create Requisitions',
      long: '',
      type: 'link',
      url: '/requisition-center',
      img: appIcons('requisition-center')
    }
  ]
  // -------------------------------------------------------------------------------------
  // loop over user apps and add only what the user can access
  // -------------------------------------------------------------------------------------
  secured.forEach((app) => {
    policies.forEach(policy => {
      if (policy.application === app.title || policy.application === app.secondary) {
        const hasFieldPipeline = (policy.application === 'Field Data' || policy.application === 'Field Pipeline') && policy.name !== 'foreman'
        if (!hasFieldPipeline) modules.push(app)
      }
    })
  })
  // -------------------------------------------------------------------------------------
  // add the admin apps if the user is an admin
  // -------------------------------------------------------------------------------------
  modules = modules.concat(regular)
  if (admin) { modules = modules.concat(adminAccess) }

  return modules
}

export default dashboard
