import '@babel/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './containers/App/App.jsx'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers/Index.jsx'
import backboneUtils from './lib/utils.js'
import { datadogRum } from '@datadog/browser-rum'
// import cookies from './lib/cookies'
// import { AUTHTOKEN } from './lib/constants'
import './lib/assets/favicons/favicon.ico'

// --------------------------------------------------------
// check local storage for userdata, if it exists set it to
// default state in the store and set the user
// --------------------------------------------------------
if (window.localStorage.user === undefined) {
  window.localStorage.user = null
} else {
  JSON.parse(window.localStorage.user)
}
// --------------------------------------------------------
// Application Entry point state (these are the Application
// wide settings that get set to the application)
// --------------------------------------------------------
const preloadedState = {
  userAuth: {
    entryUrl: window.location.href.replace(process.env.BACKBONE, ''),
    isFetching: false,
    // ----------------------------------------------------
    // clears localStorage if not authenticated
    // ----------------------------------------------------
    isAuthenticated: false, // backboneUtils.checkIfAuthenticated(),
    user: JSON.parse(window.localStorage.user),
    full_name: window.localStorage.full_name,
    authtoken: null, // cookies.get(AUTHTOKEN),
    tokenExpire: window.localStorage.tokenExpire,
    isAdmin: backboneUtils.boolean(window.localStorage.isAdmin),
    errorMessage: ''
  }
}
// ----------------------------------------------------
// integrated datadog rum to backbone
// ----------------------------------------------------
if (process.env.DATADOG_ENABLED === 'true' && process.env.NODE_ENV === 'production') {
  datadogRum.init({
    applicationId: process.env.DATADOG_APPLICATION_ID,
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'backbone-ui',
    env: process.env.DATADOG_ENV,
    version: process.env.DATADOG_VERSION,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    // Use regular expressions to match multiple origins
    allowedTracingUrls: [/https:\/\/.*\.controlac\.com/]
  })
}
// --------------------------------------------------------
// for using multiple store enhancers
// --------------------------------------------------------
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
// --------------------------------------------------------
// creates an app wide redux store (similar to a database,
// but client side)
// --------------------------------------------------------
export const store = createStore(
  // ------------------------------------------------------
  // load the main reducer that loads all the other
  // reducers
  // ------------------------------------------------------
  rootReducer,
  // ------------------------------------------------------
  // loads a default empty object into the app; we can
  // specify default data if desired
  // ------------------------------------------------------
  preloadedState,
  // ------------------------------------------------------
  // allows us to use the redux plugin on google chrome
  // && allows Async API calls from actions
  // ------------------------------------------------------
  composeEnhancers(
    applyMiddleware(
      // --------------------------------------------------
      // thunk allows all of our actions to be async
      // actions
      // --------------------------------------------------
      thunk
    )
  )
)
// --------------------------------------------------------
// Needed for onTouchTap
// http://stackoverflow.com/a/34015469/988941
// --------------------------------------------------------
// injectTapEventPlugin()
ReactDOM.render(
  <Provider store={store}>
    <App store={store} />
  </Provider>,
  document.getElementById('root')
)
