/* eslint-disable no-case-declarations */
import moment from 'moment'

const receivingLog = (
  state = {
    errorMessage: null,
    isFetching: false,
    callingAction: null,
    errorCaller: null,
    userInfo: {},
    itemPage: 1,
    itemPageCount: 1,
    items: [],
    selectedItem: {},
    item: {
      received_on: moment().format('YYYY-MM-DD'),
      po_number: null,
      job_number: null,
      details: null,
      vendor: null,
      location: null
    },
    itemModalOpen: false,
    newItem: {
      received_on: moment().format('YYYY-MM-DD'),
      po_number: null,
      job_number: null,
      details: null,
      vendor: null,
      location: null
    },
    searchTerm: null,
    filterModalOpen: false,
    editCommentObj: {},
    editCommentModalOpen: false
  },
  action
) => {
  switch (action.type) {
  case 'RECEIVING_LOG_ERROR':
    const error = Object.assign({}, action.errorMessage)
    return Object.assign({}, state, {
      errorMessage: error.response.data.error,
      errorCaller: action.errorCall,
      isFetching: false
    })
  case 'FETCH_RECEIVING_LOG':
    return Object.assign({}, state, {
      isFetching: true,
      callingAction: action.callingAction
    })
  case 'RECEIVING_LOG_USER_INFO':
    return Object.assign({}, state, {
      isFetching: true,
      userInfo: action.userInfo
    })
  case 'RECEIVING_LOG_ITEMS':
    return Object.assign({}, state, {
      isFetching: false,
      itemPage: action.itemPage,
      itemPageCount: action.itemPageCount,
      items: action.items
    })
  case 'RECEIVING_LOG_SELECTED_ITEM':
    return Object.assign({}, state, {
      selectedItem: action.selectedItem,
      isFetching: false
    })
  case 'RECEIVING_LOG_NEW_ITEM_MODAL':
    return Object.assign({}, state, {
      itemModalOpen: action.itemModalOpen,
      // -------------------------------------
      // clear the item when the modal opens
      // or closes
      // -------------------------------------
      newItem: {
        received_on: moment().format('YYYY-MM-DD')
      }
    })
  case 'RECEIVING_LOG_FILTER_MODAL':
    return Object.assign({}, state, {
      filterModalOpen: action.filterModalOpen
    })
  case 'RECEIVING_JOB_NUMBER_SEARCH':
    return Object.assign({}, state, {
      searchTerm: action.searchTerm
    })
  case 'RECEIVING_LOG_EDIT_MODAL':
    return Object.assign({}, state, {
      editCommentObj: action.editCommentObj,
      editCommentModalOpen: action.editCommentModalOpen
    })
  default:
    return state
  }
}

export default receivingLog
