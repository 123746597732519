/* eslint-disable no-async-promise-executor */

import { backbone } from '../../lib/http'

function getUser () {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await backbone.get('/auth?zoom=companies,departments,policies,connected_accounts,devices')
      const user = res.data.data.users[0]
      resolve(user)
    } catch (err) {
      reject(err)
    }
  })
}

export default getUser
